import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { City } from 'core/entities/geo';
import { APIService } from 'core/services/index';

// TODO: переписать на новый API по готовности
export class CitiesService extends APIService {
  public async fetch(): Promise<{ cities: Array<City> }> {
    const response = await this.execute('/api/v2/cities');
    return this.processResponse(response);
  }

  public async fetchBySlug(slug: string): Promise<{ cities: Array<City> }> {
    const response = await this.execute('/api/v2/cities', 'GET', {
      params: {
        slug
      }
    });
    return this.processResponse(response);
  }

  public async fetchByCoords(lat: number, lng: number): Promise<{ cities: Array<City> }> {
    const response = await this.execute('/api/v2/cities', 'GET', {
      params: {
        lat,
        lng
      }
    });
    return this.processResponse(response);
  }

  private processResponse(response: AxiosResponse): { cities: Array<City> } {
    return {
      cities: get(response, 'data.cities', {})
    };
  }
}
