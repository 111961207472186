/* eslint-disable complexity */
import styles from './flat-list-page.module.css';

import { useContext, useEffect, useRef, useState } from 'react';

import { FLATLIST_TEXT_NOTHING_FOUND, FLATLIST_TEXT_LOADING } from 'core/constants/flat-list';
import { BEST_ALLOWED_RATING_VALUE, MIN_PRICE_FOR_CITY, REVIEW_AVERAGE_FOR_CITY } from 'core/constants/review';
import { WORD_FORM_FLAT, WORD_FORM_TEXT_FOUND } from 'core/constants/word-forms';
import { Currency } from 'core/entities';
import { Breadcrumb } from 'core/entities/breadcrumb';
import { City } from 'core/entities/geo';
import { SearchMeta, SearchPageParams } from 'core/entities/search';
import { SEO } from 'core/entities/seo';
import { getFullFiltersText } from 'core/utils/filters/filters-info';
import { getFiltersFromQueryParams } from 'core/utils/filters/search-filter';
import { WordForms } from 'core/utils/word-forms';

import { FiltersContext } from 'contexts/filters/filters';
import { useSearchFlatList } from 'contexts/search/search-flat-list';

import { useHasChekedFilters } from 'hooks/filters';
import { useInfinityList } from 'hooks/flat-list/infinity-list';
import { useCalendarForFlatsList } from 'hooks/flat-list/use-calendar-for-flats';
import { useSearchQuery } from 'hooks/use-search-query';

import { Breadcrumbs } from 'components/common/breadcrumbs/breadcrumbs';
import { HTMLBlock } from 'components/common/html-block/html-block';
import { NearestCitiesList } from 'components/common/nearest-cities/nearest-cities';
import { Spinner } from 'components/common/spinner/spinner';
import { Text } from 'components/common/text/text';
import { InfinityFlatList } from 'components/flat/infinity-flat-list/infinity-flat-list';
import { LandingList } from 'components/flat/landing-list/landing-list';
import { Paginator } from 'components/paginator/flat-list';

interface FlatListPageProps {
  meta: SearchMeta;
  seo: SEO;
  bookmarksList: Array<number>;
  query: SearchPageParams;
  path: string;
  accept: string;
  mainHost: string;
  isMobileDevice: boolean;
  city?: Optional<City>;
  currency: Currency;
  cookies: string;
}

interface RenderSchemaProps {
  seo: SEO;
  currency: Currency;
  city?: Optional<City>;
  meta: SearchMeta;
}

export const renderSchema = (props: RenderSchemaProps) => {
  const minPrice: number =
    props.seo.minPrice &&
    props.seo.maxPrice &&
    props.seo.minPrice <= MIN_PRICE_FOR_CITY &&
    props.seo.maxPrice > MIN_PRICE_FOR_CITY
      ? MIN_PRICE_FOR_CITY
      : (props.seo.minPrice as number);

  return (
    <div className={styles.hidden} itemScope itemType='http://schema.org/Resort'>
      <meta itemProp='name' content={props.city ? props.city.name : props.seo.headline} />
      <meta itemProp='url' content={props.seo.canonical} />
      <meta itemProp='description' content={props.seo.description} />
      {!!props.seo.maxPrice && !!props.seo.minPrice && (
        <meta
          itemProp='priceRange'
          content={`от ${minPrice.toString()} до ${props.seo.maxPrice} ${props.currency.entity}/сутки`}
        />
      )}
      {props.city && (
        <>
          <meta
            itemProp='hasMap'
            content={`https://yandex.ru/maps/?ll=${props.city.center.lng},${props.city.center.lat}&z=12`}
          />
          <div itemProp='address' itemScope itemType='http://schema.org/PostalAddress'>
            <meta itemProp='streetAddress' content={props.city.name} />
            <meta itemProp='addressLocality' content={props.city.name} />
            {props.city.region && <meta itemProp='addressRegion' content={props.city.region.name} />}
            <meta itemProp='addressCountry' content={props.city.country.name} />
          </div>

          <div itemProp='geo' itemScope itemType='http://schema.org/GeoCoordinates'>
            <meta itemProp='latitude' content={props.city.center.lat.toString()} />
            <meta itemProp='longitude' content={props.city.center.lng.toString()} />
          </div>
        </>
      )}
      {!!props.seo.reviewsCount && !!props.seo.reviewsAverage && props.seo.reviewsAverage > REVIEW_AVERAGE_FOR_CITY && (
        <div itemProp='aggregateRating' itemScope itemType='https://schema.org/AggregateRating'>
          <meta itemProp='ratingValue' content={props.seo.reviewsAverage.toFixed(1)} />
          <meta itemProp='reviewCount' content={props.seo.reviewsCount.toString()} />
          <meta itemProp='bestRating' content={BEST_ALLOWED_RATING_VALUE.toString()} />
        </div>
      )}
    </div>
  );
};

export const FlatListPage = (props: FlatListPageProps) => {
  const [title, setTitle] = useState(props.seo.headline);
  const [filtersNotice, setFiltersNotice] = useState(props.seo.noticeTop);
  const [noticeTop] = useState(props.seo.noticeTop);
  const { adultChecked, setLoading } = useContext(FiltersContext);
  const searchFlatListContext = useSearchFlatList();

  const { query } = useSearchQuery(props.query);
  const bottomOfList = useRef<HTMLDivElement>(null);

  const filters = getFiltersFromQueryParams(query, props.currency);
  const chekedFilters = useHasChekedFilters(filters, props.currency, adultChecked);

  const infinityList = useInfinityList({
    bottomOfListRef: bottomOfList,
    cookies: props.cookies,
    path: props.path,
    query,
    accept: props.accept,
    mainHost: props.mainHost,
    currentCurrencyId: props.currency.id,
    boundaryKey: props.meta.filters.boundaryKey,
    setFiltersLoading: setLoading
  });
  const calendarHook = useCalendarForFlatsList(props.mainHost);

  const noticeText = chekedFilters.filtersApplied ? filtersNotice : noticeTop;

  const makeBreadcrumbs = (): Array<Breadcrumb> => {
    const breadcrumbs: Array<Breadcrumb> = props.meta.breadcrumbs.map((breadcrumb, index) => {
      if (index + 1 === props.meta.breadcrumbs.length) {
        return {
          title: breadcrumb.text
        };
      }
      return {
        title: breadcrumb.text,
        slug: breadcrumb.url
      };
    });
    return breadcrumbs;
  };

  useEffect(() => {
    if (chekedFilters.filtersApplied) {
      setTitle(
        `${new WordForms(WORD_FORM_TEXT_FOUND.ONE, WORD_FORM_TEXT_FOUND.FEW, WORD_FORM_TEXT_FOUND.MANY).choosePlural(
          searchFlatListContext.totalFlats
        )} ${new WordForms(WORD_FORM_FLAT.ONE, WORD_FORM_FLAT.FEW, WORD_FORM_FLAT.MANY).getPlural(
          searchFlatListContext.totalFlats,
          '\xa0',
          true
        )}`
      );
      setFiltersNotice(getFullFiltersText(filters, chekedFilters, props.currency));
    } else {
      setTitle(props.seo.headline);
    }
  }, [chekedFilters, filters, searchFlatListContext.meta]);

  useEffect(() => {
    if (searchFlatListContext.totalFlats === 0) {
      if (chekedFilters.filtersApplied) {
        setTitle(FLATLIST_TEXT_NOTHING_FOUND);
      }
    }
  }, [chekedFilters, searchFlatListContext.totalFlats, searchFlatListContext.meta]);

  const schemas = renderSchema({ seo: props.seo, currency: props.currency, city: props.city, meta: props.meta });

  return (
    <div className={styles.root}>
      {schemas}
      <div className={styles.content}>
        <Text As='h1' size={20} desktopSize={36}>
          {infinityList.isLoading ? FLATLIST_TEXT_LOADING : title}
        </Text>

        <div className={styles.noticeTop}>{noticeText}</div>

        <InfinityFlatList
          mainHost={props.mainHost}
          accept={props.accept}
          path={props.path || ''}
          seoCards={props.seo.seoCards.cards}
          noticeTop={noticeTop}
          starredIdList={props.bookmarksList ? props.bookmarksList : []}
          defaultQuery={props.query}
          currency={props.currency}
          city={props.city}
          isMobileDevice={props.isMobileDevice}
          cookies={props.cookies}
          infinityListHook={infinityList}
          calendarHook={calendarHook}
        />
        {infinityList.isLoading && !infinityList.showSkeleton && <Spinner size={32} />}
        <div ref={bottomOfList} />
        <Paginator path={props.seo.canonical} meta={searchFlatListContext.meta} limit={5} hidden={true} />
      </div>
      <div>
        {props.meta.breadcrumbs && props.meta.breadcrumbs.length > 1 && <Breadcrumbs breadcrumbs={makeBreadcrumbs()} />}
        {props.meta.landings && (
          <LandingList landings={props.meta.landings} totalFlats={searchFlatListContext.totalFlats} />
        )}
        {props.meta.nearestCities && <NearestCitiesList nearestCities={props.meta.nearestCities} />}
        {!chekedFilters.filtersApplied && props.seo.noticeDown && (
          <HTMLBlock className={styles.noticeDown} html={props.seo.noticeDown} />
        )}
      </div>
    </div>
  );
};
