import styles from './nearest-cities.module.css';

import { FLATLIST_TEXT_FOOTER_NEAREST_CITIES } from 'core/constants/flat-list';
import { NearestCities } from 'core/entities/search';

import { FlatListFooterLinkingItems } from 'components/common/flat-list-footer-linking/flat-list-footer-linking';

interface NearestCitiesListProps {
  nearestCities: Array<NearestCities>;
}

export const NearestCitiesList = ({ nearestCities }: NearestCitiesListProps) => {
  if (!nearestCities || nearestCities.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <FlatListFooterLinkingItems
        name={FLATLIST_TEXT_FOOTER_NEAREST_CITIES}
        items={nearestCities.map(({ name, url }) => ({ text: name, url }))}
      />
    </div>
  );
};
