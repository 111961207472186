import { useExperimentsContext } from 'yandex-metrica-ab-react';

export enum AvailableFlags {
  flatCalendar = 'Показывать окно календаря при клике на объявление',
  newCalendar = 'Показывать новую версию календаря',
  yandexWidget = 'Показывать виджет мгновенного входа Яндекс'
}

export const useMetrikaExperiments = () => {
  const { flags } = useExperimentsContext<typeof AvailableFlags>();

  return {
    flatCalendar: flags.flatCalendar?.[0],
    newCalendar: flags.newCalendar?.[0],
    yandexWidget: flags.yandexWidget?.[0]
  };
};
