import * as Sentry from '@sentry/nextjs';
import get from 'lodash/get';
import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import { errorText } from 'core/constants/error-text';
import { BookingInterval } from 'core/entities/booking';
import { DatesFilters } from 'core/entities/filters';
import { CardFlat } from 'core/entities/flats';
import { AvailabilityService } from 'core/services/availability';

import { FlatCalendarContext } from 'contexts/filters/filter-flat-calendar';
import { FiltersContext } from 'contexts/filters/filters';

export interface CalendarForFlatsListHook {
  flatsWithDatesError: string;
  isRefusedSelectDates: boolean;
  selectedDates: Optional<DatesFilters>;
  selectedFlat: Optional<CardFlat>;
  unavailableSelectedFlat: Optional<CardFlat>;
  checkFlat: (range: BookingInterval, setIsBtnLoading: Dispatch<SetStateAction<boolean>>) => Promise<void>;
  handleFlatClick: (flat: CardFlat) => void;
  handleFlatPickWithDates: (dates: DatesFilters) => void;
  setSelectedDates: Dispatch<SetStateAction<Optional<DatesFilters>>>;
  setIsRefusedSelectDates: Dispatch<SetStateAction<boolean>>;
  setUnavailableSelectedFlat: Dispatch<SetStateAction<Optional<CardFlat>>>;
}

export const useCalendarForFlatsList = (mainHost: string): CalendarForFlatsListHook => {
  const { filters, setFilters, setLoading } = useContext(FiltersContext);
  const { unavailableFlatModal } = useContext(FlatCalendarContext);

  const [isRefusedSelectDates, setIsRefusedSelectDates] = useState(false);
  const [selectedFlat, setSelectedFlat] = useState<Optional<CardFlat>>(null);
  const [selectedDates, setSelectedDates] = useState<Optional<DatesFilters>>(null);
  const [unavailableSelectedFlat, setUnavailableSelectedFlat] = useState<Optional<CardFlat>>(null);
  const [flatsWithDatesError, setFlatsWithDatesError] = useState('');

  const handleFlatClick = useCallback((flat: CardFlat) => {
    setSelectedFlat(flat);
  }, []);

  const checkFlat = useCallback(
    async (range: BookingInterval, setIsBtnLoading: Dispatch<SetStateAction<boolean>>) => {
      if (!selectedFlat || !range.arrival || !range.departure) {
        return;
      }

      const availabilityService = new AvailabilityService(mainHost);

      setFlatsWithDatesError('');

      try {
        const isFlatAvailable = await availabilityService.check(
          selectedFlat.id,
          new Date(range.arrival),
          new Date(range.departure)
        );

        if (isFlatAvailable) {
          setUnavailableSelectedFlat(null);
        }
      } catch (err) {
        const status = get(err, 'response.status');
        const error = get(err, 'response.data.error', errorText.defaultError);

        if (status === 422 || status === 404) {
          setUnavailableSelectedFlat(selectedFlat);
        } else {
          Sentry.captureException(err);
        }

        setFlatsWithDatesError(error);
      } finally {
        // timeout во избежание резкого дерганья лоадера
        setTimeout(() => {
          setIsBtnLoading(false);
        }, 300);
      }
    },
    [selectedFlat]
  );

  const handleFlatPickWithDates = useCallback(
    (dates: DatesFilters) => {
      if (unavailableSelectedFlat) {
        setTimeout(() => {
          unavailableFlatModal.onOpen();
          setLoading((prev) => ({ ...prev, calendar: false }));
        }, 0);
      } else {
        setFilters({ ...filters, dates });
        window.open(selectedFlat?.url, '_blank');
      }
    },
    [selectedFlat, unavailableSelectedFlat]
  );

  return {
    flatsWithDatesError,
    isRefusedSelectDates,
    selectedDates,
    selectedFlat,
    unavailableSelectedFlat,
    checkFlat,
    handleFlatClick,
    handleFlatPickWithDates,
    setSelectedDates,
    setIsRefusedSelectDates,
    setUnavailableSelectedFlat
  };
};
