import styles from './search-info.module.css';

import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { Filters } from 'core/entities/filters';
import { SearchMeta } from 'core/entities/search';
import { WordForms } from 'core/utils/word-forms';

import { useHasChekedFilters } from 'hooks/filters';
import { useFlatsRoutes } from 'hooks/router/use-flats-routes';
import { useCurrency } from 'hooks/use-currency';

import { Button } from 'components/common/button/button';
import { Text } from 'components/common/text/text';

interface SearchInfoProps {
  className?: string;
  mainHost: string;
  filters: Filters;
  meta: SearchMeta;
  totalFlats: number;
  mapShifted: boolean;
  loading: boolean;
  errorValue?: string;
}

export const SearchInfo = (props: SearchInfoProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { goToFlatsListPage } = useFlatsRoutes(props.mainHost);
  const currency = useCurrency();
  const { filterWithoutMapApplied } = useHasChekedFilters(props.filters, currency);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    goToFlatsListPage(props.filters);
  }, [goToFlatsListPage, props.filters]);

  const getInfoText = useCallback(() => {
    const totalFlats =
      filterWithoutMapApplied || props.mapShifted || !props.meta.filters.city ? props.totalFlats : props.meta.total;
    let text = new WordForms('объект', 'объекта', 'объектов').getPlural(totalFlats, '\xa0', true);
    if (!props.mapShifted && props.meta.filters.city) {
      text = text + ` в ${props.meta.filters.city.namePrep}`;
    } else {
      text = text + ' на карте';
    }
    return text;
  }, [props.mapShifted, props.totalFlats, filterWithoutMapApplied]);

  return (
    <div className={classNames(styles.root, props.className)}>
      {props.totalFlats ? (
        <Text className={styles.heading} As='p' size={20} bold>
          {getInfoText()}
        </Text>
      ) : (
        <Text className={styles.heading} As='p' size={20} bold>
          Нет объектов на карте
        </Text>
      )}
      <div className={styles.wrapper}>
        <Button
          className={styles.button}
          onClick={handleClick}
          isLoading={props.loading || isLoading}
          text='Показать списком'
          block
        />
      </div>
    </div>
  );
};
