import styles from './flat-list-load-more.module.css';

import { Button } from 'components/common/button/button';

interface FlatListLoadMoreProps {
  onClick: () => void;
}

export const FlatListLoadMore = ({ onClick }: FlatListLoadMoreProps) => {
  return (
    <div className={styles.root}>
      <Button className={styles.button} text='Показать еще...' onClick={onClick} />
    </div>
  );
};
