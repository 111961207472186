import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { RUS_ID } from 'core/constants/currency';
import { LIMIT_FOR_DOTS } from 'core/constants/flats';
import { Point } from 'core/entities/geo';
import { SearchPageParams } from 'core/entities/search';
import { LocationContext } from 'core/services/context/context';
import { APIService } from 'core/services/index';
import { parseCookies } from 'core/utils/cookies';

export class LocationService extends APIService {
  private readonly cookies: Optional<string>;

  constructor(
    host: string,
    correlationID: Optional<string> = null,
    accessToken: Optional<string> = null,
    cookies: Optional<string> = null
  ) {
    super(host, correlationID, accessToken);
    this.cookies = cookies;
  }

  public async fetchDots(path: string, query: SearchPageParams): Promise<LocationContext> {
    const response = await this.execute('/api/v3/context/location', 'GET', {
      params: {
        currency: this.getCurrencyId(this.cookies as string),
        ...omit(query, ['zoom', 'pointName']),
        path,
        limit: LIMIT_FOR_DOTS
      }
    });
    return this.processResponse(response);
  }

  public async fetchDotsByBox(
    point: Point,
    box: number[][],
    query: SearchPageParams,
    newCalendar?: string,
    boundaryKey?: number
  ): Promise<LocationContext> {
    const response = await this.execute('/api/v3/context/location', 'GET', {
      params: {
        currency: this.getCurrencyId(this.cookies as string),
        ...omit(query, ['lat', 'lng', 'zoom', 'pointName']),
        minLat: box[0][0],
        minLng: box[0][1],
        maxLat: box[1][0],
        maxLng: box[1][1],
        limit: 200,
        newCalendar,
        boundaryKey
      }
    });
    return this.processResponse(response);
  }

  public async fetchDotsByCenter(
    point: Point,
    query: SearchPageParams,
    boundaryKey?: number
  ): Promise<LocationContext> {
    const response = await this.execute('/api/v3/context/location', 'GET', {
      params: {
        currency: this.getCurrencyId(this.cookies as string),
        ...omit(query, ['minLat', 'minLng', 'maxLat', 'maxLng', 'zoom', 'pointName']),
        lat: point.lat,
        lng: point.lng,
        limit: LIMIT_FOR_DOTS,
        boundaryKey
      }
    });
    return this.processResponse(response);
  }

  private getCurrencyId(cookies: string) {
    const parsedCookies = parseCookies(cookies);
    return parsedCookies && parsedCookies.currency ? Number(parsedCookies.currency) : RUS_ID;
  }

  private processResponse(response: AxiosResponse): LocationContext {
    return {
      meta: get(response, 'data.meta', {}),
      flats: get(response, 'data.flats', [])
    };
  }
}
