import React from 'react';

import { NOOP } from 'core/utils/NOOP';

interface MapStarredContextType {
  bookmarksList: Array<number>;
  add: (id: number) => void;
  remove: (id: number) => void;
}

export const MapStarredContext = React.createContext<MapStarredContextType>({
  bookmarksList: [],
  add: NOOP,
  remove: NOOP
});

interface MapStarredProviderProps {
  bookmarksList: Array<number>;
  children: React.ReactChild;
}

export const MapStarredProvider = (props: MapStarredProviderProps) => {
  const [bookmarksList, setBookmarksList] = React.useState(props.bookmarksList);

  const add = React.useCallback(
    (id: number) => {
      setBookmarksList((prevState) => [...prevState, id]);
    },
    [bookmarksList]
  );

  const remove = React.useCallback(
    (id: number) => {
      const list = bookmarksList.filter((flatId) => flatId !== id);
      setBookmarksList(list);
    },
    [bookmarksList]
  );

  return (
    <MapStarredContext.Provider value={{ bookmarksList, add, remove }}>{props.children}</MapStarredContext.Provider>
  );
};
