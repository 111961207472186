import groupBy from 'lodash/groupBy';
import { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ymaps, { IMapOptions, IMapState } from 'yandex-maps';

import { YANDEX_KEY } from 'core/constants/yandex-api';
import { Currency } from 'core/entities';
import { MapFilters } from 'core/entities/filters';
import { DotFlat, CardFlat } from 'core/entities/flats';
import { MapBox } from 'core/entities/geo';
import { getPricePerDay } from 'core/utils/flat/get-price-per-day';

import { SearchBarContext } from 'contexts/search/search-bar';

import { useScript } from 'hooks/index';

export interface MapObjectOptions {
  flat: CardFlat;
  iconLayout: ymaps.IClassConstructor<ymaps.layout.templateBased.Base>;
  iconShape: {
    type: string;
    coordinates: number[][];
  };
  zIndex: number;
  showApproximatePrice: boolean;
}

export interface MapObject {
  geometry?: {
    coordinates: number[][];
  };
  id?: number;
  options?: MapObjectOptions;
}

interface ObjectCollection extends ymaps.objectManager.ObjectCollection {
  getAll: () => Array<MapObject>;
}

interface Cluster extends ymaps.Cluster {
  features: Array<MapObject>;
}

interface ObjectManager extends ymaps.ObjectManager {
  duration?: Optional<number>;
}

const PLACEMARK_CLASS = 'flat-placemark';
const PLACEMARK_HOVER_CLASS = `${PLACEMARK_CLASS}--hover`;
const PLACEMARK_ACTIVE_CLASS = `${PLACEMARK_CLASS}--active`;
const PLACEMARK_STARRED_CLASS = `${PLACEMARK_CLASS}-starred`;

const CLUSTER_OPTIONS = {
  clusterize: true,
  clusterOpenBalloonOnClick: false,
  clusterDisableClickZoom: true,
  minClusterSize: 3,
  gridSize: 128
};

interface Options {
  mapRef: RefObject<HTMLDivElement>;
  flats: Array<CardFlat>;
  dots: Array<DotFlat>;
  state: IMapState;
  options: IMapOptions;
  mapFilters: MapFilters;
  currency: Currency;
  bookmarksList: Array<number>;
  duration: Optional<number>;
  bbox?: MapBox;
  showApproximatePrice: boolean;
  onCloseModal: () => void;
  onMoveMap?: () => void;
}

export const useSearchYmaps = (options: Options) => {
  const { setShowFilters } = useContext(SearchBarContext);
  const [yandexMap, setYandexMap] = useState<Optional<typeof ymaps>>(null);
  const mapInstance = useRef<Optional<ymaps.Map>>(null);
  const mapObjectManager = useRef<Optional<ObjectManager>>(null);
  const dotObjectManager = useRef<Optional<ymaps.ObjectManager>>(null);
  const selectedMapElement = useRef<Optional<HTMLElement>>(null);

  const ymapsIsReady = typeof window === 'object' && typeof window.ymaps === 'object';
  const status = useScript(`https://api-maps.yandex.ru/2.1/?apikey=${YANDEX_KEY}&lang=ru_RU`, !ymapsIsReady);

  const [selectedObject, setSelectedObject] = useState<Optional<MapObject>>(null);
  const [center, setCenter] = useState(options.state.center);
  const [box, setBox] = useState<Optional<number[][]>>(null);
  const isClustering = options.state.zoom && options.state.zoom > 16 && options.flats.length < 25;

  const resetSelectedObject = useCallback(() => {
    setSelectedObject(null);
  }, []);

  const resetActiveElement = useCallback(() => {
    if (selectedMapElement.current) {
      selectedMapElement.current.classList.remove(PLACEMARK_ACTIVE_CLASS);
    }
  }, [selectedMapElement]);

  useEffect(() => {
    if (status === 'ready') {
      setYandexMap(window.ymaps);
    } else {
      setYandexMap(null);
    }
  }, [status]);

  const containsPoint = useCallback(
    (point: number[][] | number[]): boolean => {
      if (yandexMap && mapInstance.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line
        return yandexMap.util.bounds.containsPoint(mapInstance.current.getBounds(), point);
      }
      return false;
    },
    [yandexMap, mapInstance.current]
  );

  const starredUpdate = useCallback(() => {
    const allElements = document.querySelectorAll(`div.${PLACEMARK_CLASS}`);
    for (let i = 0; i < allElements.length; i = i + 1) {
      let elementId = allElements[i].id || '';
      elementId = elementId.replace('flat-', '');
      const elIsStarred = isStarred(options.bookmarksList, Number(elementId));
      if (elIsStarred) {
        allElements[i].classList.add(PLACEMARK_STARRED_CLASS);
      } else {
        allElements[i].classList.remove(PLACEMARK_STARRED_CLASS);
      }
    }
  }, [options.bookmarksList]);

  const getDefaultBounds = useCallback((): Optional<number[][]> => {
    let defaultBounds = null;
    if (options.bbox) {
      defaultBounds = [
        [options.bbox.pointMin.lat, options.bbox.pointMin.lng],
        [options.bbox.pointMax.lat, options.bbox.pointMax.lng]
      ];
    }
    if (!options.bbox && mapInstance.current) {
      defaultBounds = mapInstance.current.getBounds();
    }
    return defaultBounds;
  }, [mapInstance.current]);

  const getVisibleObjects = useCallback((): Array<MapObject> => {
    if (mapObjectManager.current && mapInstance.current) {
      const objectCollection = mapObjectManager.current.objects as ObjectCollection;
      return objectCollection.getAll().filter((obj) => {
        if (obj.geometry) {
          return containsPoint(obj.geometry.coordinates);
        }
        return false;
      });
    }
    return [];
  }, [containsPoint]);

  const updateObjectIndents = useCallback(
    (showApproximatePrice: boolean) => {
      if (mapObjectManager.current && yandexMap) {
        setObjectIndents(
          getVisibleObjects(),
          options.currency.entity,
          options.bookmarksList,
          options.duration,
          mapObjectManager.current,
          yandexMap,
          showApproximatePrice
        );
      }
    },
    [setObjectIndents, getVisibleObjects, yandexMap]
  );

  const clearObjectsWhileMapShifted = () => {
    // очистка объектов + установка новых координат при сдвиге/зуме
    if (mapInstance.current) {
      mapInstance.current.events.add('boundschange', (event: ymaps.Event) => {
        if (mapObjectManager.current) {
          const objectCollection = mapObjectManager.current.objects as ObjectCollection;
          const objects = objectCollection.getAll().flat();
          if (objects.length > 50) {
            objects.forEach((object, index) => {
              if (index < 50 && mapObjectManager.current) {
                mapObjectManager.current.objects.remove(objects[index]);
              }
            });
          }
        }
        if (dotObjectManager.current) {
          const objectCollection = dotObjectManager.current.objects as ObjectCollection;
          const objects = objectCollection.getAll().flat();
          if (objects.length > 200) {
            objects.forEach((object, index) => {
              if (index < 200 && dotObjectManager.current) {
                dotObjectManager.current.objects.remove(objects[index]);
              }
            });
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCenter(event.get('newCenter'));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setBox(event.get('newBounds'));
      });
    }
  };

  const boundsChangeHandler = (event: ymaps.Event) => {
    const zoom = event.get('newZoom');
    const objects = getVisibleObjects();

    if (mapInstance.current && mapObjectManager.current && objects.length < 25 && zoom > 16) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mapObjectManager.current.options.set({
        clusterize: false
      });
      updateObjectIndents(options.showApproximatePrice);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mapObjectManager.current.options.set(CLUSTER_OPTIONS);
      starredUpdate();
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (yandexMap && options.mapRef.current && mapInstance.current) {
      mapInstance.current.events.add('boundschange', boundsChangeHandler);
    }
  }, [options.showApproximatePrice, yandexMap]);

  const init = useCallback(() => {
    if (yandexMap && options.mapRef.current) {
      mapInstance.current = new yandexMap.Map(options.mapRef.current, options.state, options.options);
      mapObjectManager.current = new yandexMap.ObjectManager(isClustering ? {} : CLUSTER_OPTIONS);
      dotObjectManager.current = new yandexMap.ObjectManager({});
      mapInstance.current.geoObjects.add(mapObjectManager.current);
      mapInstance.current.geoObjects.add(dotObjectManager.current);

      setBox(getDefaultBounds());

      // меняем настройки кластеризации при сдвиге/зума
      mapInstance.current.events.add('boundschange', boundsChangeHandler);

      clearObjectsWhileMapShifted();

      // закрытие выбранного объявления при сдвиге/зуме
      mapInstance.current.events.add('boundschange', () => {
        options.onCloseModal();
        resetActiveElement();
        setTimeout(() => {
          setShowFilters(false);
        }, 500);
      });

      mapInstance.current.events.add('boundschange', () => {
        if (options.onMoveMap) {
          options.onMoveMap();
        }
      });

      mapInstance.current.events.add('click', () => {
        options.onCloseModal();
        resetActiveElement();
      });

      const onObjectEvent = (event: ymaps.Event) => {
        if (!mapObjectManager.current) {
          return;
        }
        const objectId = event.get('objectId');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const object: Optional<MapObject> = mapObjectManager.current.objects.getById(objectId);
        if (!object) {
          return;
        }
        const element = document.getElementById(`flat-${object.id ? object.id : 0}`);
        if (!element) {
          return;
        }
        switch (event.get('type')) {
          case 'mouseenter':
            element.classList.add(PLACEMARK_HOVER_CLASS);
            break;
          case 'mouseleave':
            element.classList.remove(PLACEMARK_HOVER_CLASS);
            break;
          case 'click':
            resetActiveElement();
            selectedMapElement.current = element;
            setSelectedObject(object);
            break;
          default:
            break;
        }
      };

      // eslint-disable-next-line complexity
      const onClusterEvent = (event: ymaps.Event) => {
        if (!mapObjectManager.current) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const cluster = mapObjectManager.current.clusters.getById(event.get('objectId')) as Optional<Cluster>;
        if (!cluster) {
          return;
        }
        const object = cluster.features[0];
        const flat = object.options ? object.options.flat : ({} as CardFlat);
        const element = document.getElementById(`flat-${flat.id ? flat.id : 0}`) as HTMLElement;
        const showApproximatePrice = object.options ? object.options.showApproximatePrice : false;

        switch (event.get('type')) {
          case 'add':
            mapObjectManager.current.clusters.setClusterOptions(
              cluster.id,
              setFlatOptions(
                yandexMap,
                flat,
                options.bookmarksList,
                Number(mapObjectManager.current.duration) || options.duration,
                options.currency.entity,
                0,
                showApproximatePrice
              )
            );
            break;
          case 'mouseenter':
            element.classList.add(PLACEMARK_HOVER_CLASS);
            break;
          case 'mouseleave':
            element.classList.remove(PLACEMARK_HOVER_CLASS);
            break;
          case 'click':
            resetActiveElement();
            selectedMapElement.current = element;
            setSelectedObject(object);
            break;
          default:
            break;
        }
      };

      mapObjectManager.current.objects.events.add(['mouseenter', 'mouseleave', 'click'], onObjectEvent);
      mapObjectManager.current.clusters.events.add(['add', 'mouseenter', 'mouseleave', 'click'], onClusterEvent);

      if (
        options.bbox ||
        (options.mapFilters.maxLat &&
          options.mapFilters.maxLng &&
          options.mapFilters.minLat &&
          options.mapFilters.minLng)
      ) {
        addDotsToObjectManager(dotObjectManager.current, yandexMap, options.dots);
        addFlatsToObjectManager(
          mapObjectManager.current,
          yandexMap,
          options.flats,
          options.currency.entity,
          options.bookmarksList,
          options.duration,
          options.showApproximatePrice
        );
      }

      mapObjectManager.current.clusters.events.add('add', () => {
        if (mapInstance.current) {
          const zoom = mapInstance.current.getZoom();
          const objects = getVisibleObjects();

          if (mapInstance.current && mapObjectManager.current && objects.length < 25 && zoom > 17) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapObjectManager.current.options.set({
              clusterize: false
            });
            updateObjectIndents(options.showApproximatePrice);
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapObjectManager.current.options.set(CLUSTER_OPTIONS);
            starredUpdate();
          }
        }
      });

      if (isClustering) {
        updateObjectIndents(options.showApproximatePrice);
      }
    }
  }, [yandexMap, options.mapRef.current]);

  useEffect(() => {
    if (selectedObject && selectedMapElement.current) {
      if (selectedMapElement.current) {
        selectedMapElement.current.classList.add(PLACEMARK_ACTIVE_CLASS);
      }
    }
  }, [selectedObject, selectedMapElement]);

  useEffect(() => {
    starredUpdate();
  }, [starredUpdate]);

  useEffect(() => {
    if (mapObjectManager && mapObjectManager.current) {
      mapObjectManager.current.duration = options.duration;
    }
  }, [options.duration]);

  useEffect(() => {
    if (yandexMap && !mapInstance.current) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      yandexMap.ready(init).then();
    }
  }, [yandexMap, mapInstance.current]);

  return {
    box,
    yandexMap,
    mapInstance,
    selectedObject,
    resetSelectedObject,
    center,
    setCenter,
    dotObjectManager,
    mapObjectManager,
    resetActiveElement
  };
};

function isStarred(bookmarksList: Array<number>, flatId: number) {
  return bookmarksList.includes(flatId);
}

const makeLayout = (
  flatId: number,
  bookmarksList: Array<number>,
  flatPrice: number,
  currencyEntity: string,
  indent = 0,
  showApproximatePrice: boolean
) => {
  const price = showApproximatePrice ? `От ${flatPrice}` : flatPrice;

  return `
    <div
      id='flat-${flatId}'
      class='${PLACEMARK_CLASS} ${isStarred(bookmarksList, flatId) ? PLACEMARK_STARRED_CLASS : ''}'
      style='transform: translateY(-${indent}px)'
    >
     <span class='flat-placemark__price'>${price}</span> ${currencyEntity}
    </div>
  `;
};

const makeLayoutDot = () => {
  return '<div class="flat-dot"></div>';
};

const setFlatOptions = (
  yandexMap: typeof ymaps,
  flat: CardFlat,
  bookmarksList: Array<number>,
  duration: Optional<number>,
  currencyEntity: string,
  indent = 0,
  showApproximatePrice: boolean
  // eslint-disable-next-line max-params
) => {
  return {
    flat: {
      ...flat
    },
    iconLayout: yandexMap.templateLayoutFactory.createClass(
      makeLayout(
        flat.id,
        bookmarksList,
        getPricePerDay(flat.prices, duration),
        currencyEntity,
        indent,
        showApproximatePrice
      )
    ),
    iconShape: {
      type: 'Rectangle',
      coordinates: [
        [0, -indent],
        [showApproximatePrice ? 89 : 64, 32 - indent]
      ]
    },
    zIndex: 200,
    showApproximatePrice
  };
};

const setDotOptions = (yandexMap: typeof ymaps) => ({
  iconLayout: yandexMap.templateLayoutFactory.createClass(makeLayoutDot()),
  iconShape: {
    type: 'Rectangle',
    coordinates: [
      [0, 0],
      [5, 5]
    ]
  },
  zIndex: 100
});

const setObjectIndents = (
  objects: Array<MapObject>,
  currencyEntity: string,
  bookmarksList: Array<number>,
  duration: Optional<number>,
  objectManager: ymaps.ObjectManager,
  yandexMap: typeof ymaps,
  showApproximatePrice: boolean
  // eslint-disable-next-line max-params
) => {
  const identicalObjects = groupBy(objects, (obj: MapObject) => obj.geometry?.coordinates);
  Object.values(identicalObjects).forEach((groupedObjects: Array<MapObject>) => {
    if (groupedObjects.length > 1) {
      groupedObjects.forEach((obj, index) => {
        if (objectManager && obj && obj.options) {
          objectManager.objects.setObjectOptions(
            String(obj.id),
            setFlatOptions(
              yandexMap,
              obj.options.flat,
              bookmarksList,
              duration,
              currencyEntity,
              34 * index,
              showApproximatePrice
            )
          );
        }
      });
    }
  });
};

// eslint-disable-next-line max-params
export function addFlatsToObjectManager(
  objectManager: ymaps.ObjectManager,
  yandexMap: typeof ymaps,
  flats: Array<CardFlat>,
  currencyEntity: string,
  bookmarksList: Array<number>,
  duration: Optional<number>,
  showApproximatePrice: boolean
) {
  // objectManager.clusters.setClusterOptions()
  objectManager.add(
    flats.map((flat) => {
      return {
        type: 'Feature',
        id: flat.id,
        geometry: {
          type: 'Point',
          coordinates: [flat.point.lat, flat.point.lng]
        },
        options: setFlatOptions(yandexMap, flat, bookmarksList, duration, currencyEntity, 0, showApproximatePrice)
      };
    })
  );
}

export function addDotsToObjectManager(
  objectManager: ymaps.ObjectManager,
  yandexMap: typeof ymaps,
  dots: Array<DotFlat>
) {
  objectManager.add(
    dots.map((dot) => {
      return {
        type: 'Feature',
        id: dot.id,
        geometry: {
          type: 'Point',
          coordinates: [dot.point.lat, dot.point.lng]
        },
        options: setDotOptions(yandexMap)
      };
    })
  );
}
