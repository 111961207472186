import { createContext, ReactChild, useCallback, useState } from 'react';

import { FilterControl } from 'core/entities/filters';
import { NOOP } from 'core/utils/NOOP';

import { useLockBodyScroll } from 'hooks/scroll/use-lock-body-scroll';

export interface FlatCalendarContextType {
  selectedFlatCalendar: FilterControl;
  unavailableFlatModal: FilterControl;
}

export const FlatCalendarContext = createContext<FlatCalendarContextType>({
  selectedFlatCalendar: {
    opened: false,
    onOpen: NOOP,
    onClose: NOOP
  },
  unavailableFlatModal: {
    opened: false,
    onOpen: NOOP,
    onClose: NOOP
  }
});

interface FlatCalendarProviderProps {
  children: ReactChild;
}

export const FlatCalendarProvider = ({ children }: FlatCalendarProviderProps) => {
  const [selectedFlatCalendarOpened, setSelectedFlatCalendarOpened] = useState(false);
  const [unavailableFlatModalOpened, setUnavailableFlatModalOpened] = useState(false);
  const { setLocked } = useLockBodyScroll();

  const onSelectedFlatCalendarOpen = useCallback(() => {
    setSelectedFlatCalendarOpened(true);
    setLocked(true);
  }, []);

  const onSelectedFlatCalendarClose = useCallback(() => {
    setSelectedFlatCalendarOpened(false);
    setLocked(false);
  }, [selectedFlatCalendarOpened]);

  const onUnavailableFlatModalOpen = useCallback(() => {
    setUnavailableFlatModalOpened(true);
    setLocked(true);
  }, [unavailableFlatModalOpened]);

  const onUnavailableFlatModalClose = useCallback(() => {
    setUnavailableFlatModalOpened(false);
    setLocked(false);
  }, [unavailableFlatModalOpened]);

  return (
    <FlatCalendarContext.Provider
      value={{
        selectedFlatCalendar: {
          opened: selectedFlatCalendarOpened,
          onOpen: onSelectedFlatCalendarOpen,
          onClose: onSelectedFlatCalendarClose
        },
        unavailableFlatModal: {
          opened: unavailableFlatModalOpened,
          onOpen: onUnavailableFlatModalOpen,
          onClose: onUnavailableFlatModalClose
        }
      }}
    >
      {children}
    </FlatCalendarContext.Provider>
  );
};
