export const parseCookies = (cookies: string) => {
  if (cookies) {
    return cookies
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc: Record<string, string>, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {});
  }
  return null;
};
