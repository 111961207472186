import get from 'lodash/get';
import { useCallback, useEffect, useRef, useState } from 'react';

import { CardFlat } from 'core/entities/flats';
import { City } from 'core/entities/geo';
import { VisibilityPayload, VisibilityService } from 'core/services/visibility';

const MERGE_INTERVAL = 5000;

export const useVisibility = (filtersApplied: boolean, city: City) => {
  const [flatIdsBuffer, setFlatIdsBuffer] = useState<Array<number>>([]);
  const [flatIds, setFlatIds] = useState<Array<number>>([]);
  const [positionsBuffer, setPositionsBuffer] = useState<Array<number>>([]);
  const [positions, setPositions] = useState<Array<number>>([]);

  const timeout = useRef<Optional<NodeJS.Timeout>>(null);

  const getPerformUpdateFn = () => (service: VisibilityService) => {
    const cityID = get(city, 'id', 0);
    const payload: VisibilityPayload = {
      addIds: flatIdsBuffer.filter((item) => !flatIds.includes(item)),
      addPositions: positionsBuffer.filter((item) => !positions.includes(item)),
      cityId: cityID,
      filtersEnabled: filtersApplied
    };
    if (cityID && (payload.addIds.length || payload.addPositions.length)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      service.send(payload);
      mergeBuffers(payload.addIds, payload.addPositions);
    }
  };

  const performUpdate = useRef(getPerformUpdateFn());

  useEffect(() => {
    performUpdate.current = getPerformUpdateFn();
  }, [flatIdsBuffer, flatIds, positionsBuffer, positions]);

  const mergeBuffers = useCallback((addIds: Array<number>, addPositions: Array<number>) => {
    setFlatIds((prevFlatIds) => prevFlatIds.concat(addIds));
    setPositions((prevPositions) => prevPositions.concat(addPositions));
    setFlatIdsBuffer([]);
    setPositionsBuffer([]);
  }, []);

  const startVisibilityWatcher = useCallback(
    (service: VisibilityService, interval = MERGE_INTERVAL) => {
      timeout.current = setInterval(() => performUpdate.current(service), interval);
    },
    [performUpdate, timeout]
  );

  const stopVisibilityWatcher = useCallback(
    (service: VisibilityService) => {
      performUpdate.current(service);
      setFlatIdsBuffer([]);
      setFlatIds([]);
      setPositionsBuffer([]);
      setPositions([]);
      if (timeout.current) {
        clearInterval(timeout.current);
      }
    },
    [performUpdate, timeout]
  );

  const immediateVisibilityUpdate = useCallback(
    (service: VisibilityService) => {
      performUpdate.current(service);
    },
    [performUpdate]
  );

  const handleVisibilityChange = useCallback((visibility: boolean, flat: CardFlat) => {
    if (visibility) {
      setFlatIdsBuffer((prevFlatIdsBuffer) => [...prevFlatIdsBuffer, flat.id]);
      // TODO: position?
      setPositionsBuffer((prevPositionsBuffer) => [...prevPositionsBuffer, flat.id]);
    }
  }, []);

  return {
    handleVisibilityChange,
    startVisibilityWatcher,
    stopVisibilityWatcher,
    immediateVisibilityUpdate
  };
};
