import styles from './unavailable-flat-modal.module.css';

import { useContext } from 'react';

import {
  FLAT_UNAVAILABLE_CHOOSE_ANOTHER,
  FLAT_UNAVAILABLE_GO_ANYWAY,
  FLAT_UNAVAILABLE_TITLE
} from 'core/constants/flat-list';

import { FlatCalendarContext } from 'contexts/filters/filter-flat-calendar';
import { FiltersContext } from 'contexts/filters/filters';

import { CalendarForFlatsListHook } from 'hooks/flat-list/use-calendar-for-flats';

import { Button } from 'components/common/button/button';
import { Modal } from 'components/common/modal/modal';
import { Text } from 'components/common/text/text';

interface FlatCalendarModalProps {
  calendarHook: CalendarForFlatsListHook;
}

export const UnavailableFlatModal = ({ calendarHook }: FlatCalendarModalProps) => {
  const { filters, setFilters, setLoading } = useContext(FiltersContext);
  const { unavailableFlatModal } = useContext(FlatCalendarContext);

  const applyDatesFilters = () => {
    if (calendarHook.selectedDates) {
      setFilters({ ...filters, dates: calendarHook.selectedDates });
      setLoading((prev) => ({ ...prev, calendar: true }));
    }

    unavailableFlatModal.onClose();
  };

  const onGoAnyway = () => {
    if (calendarHook.selectedFlat) {
      window.open(calendarHook.selectedFlat.url, '_blank');
    }

    applyDatesFilters();
  };

  const footerChildren = (
    <div className={styles.footerChildren}>
      <Button
        className={styles.footerGoAnywayBtn}
        text={FLAT_UNAVAILABLE_GO_ANYWAY}
        variant='smoothBlue'
        size='lg'
        onClick={onGoAnyway}
      />
    </div>
  );

  return (
    <Modal
      title={FLAT_UNAVAILABLE_TITLE}
      opened={unavailableFlatModal.opened}
      onClose={unavailableFlatModal.onClose}
      onApply={applyDatesFilters}
      hasFooter
      footerChildren={footerChildren}
      footerClassName={styles.modalFooter}
      btnText={FLAT_UNAVAILABLE_CHOOSE_ANOTHER}
    >
      <div className={styles.modalChildren}>
        <Text As='p' size={16}>
          {calendarHook.flatsWithDatesError}
        </Text>
      </div>
    </Modal>
  );
};
