/* eslint-disable complexity */
import 'swiper/css';
import { IncomingMessage } from 'http';

import get from 'lodash/get';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import * as React from 'react';
import { getYandexMetricaAbt } from 'yandex-metrica-ab-node';
import { MetricaExperimentsContext } from 'yandex-metrica-ab-react';
import { Answer } from 'yandex-metrica-ab-react/dist/types';

import { RUS_ID } from 'core/constants/currency';
import { YANDEX_AB_METRIKA } from 'core/constants/google-analytics';
import { MOBILE_USER_AGENTS } from 'core/constants/mobile-user-agents';
import { DISABLE_MAP } from 'core/constants/yandex-api';
import { SearchPageParams } from 'core/entities/search';
import { fetchPageContext, PageContextOptions } from 'core/services/context';
import { SearchPageContext } from 'core/services/context/context';
import { getQueryParamsWithUrl } from 'core/utils/filters/search-filter';
import { getFlatListPageContext, getFlatMapPageContext } from 'core/utils/flat/get-flat-page-context';
import {
  catchErrors,
  PageContext,
  redirectFromEmptyLandingPage,
  redirectFromEmptyPaginationPage,
  redirectFromMap
} from 'core/utils/search-page';

import { FlatCalendarProvider } from 'contexts/filters/filter-flat-calendar';
import { getSearchFlatListInitialState, SearchFlatListProvider } from 'contexts/search/search-flat-list';

import { useLoginYandex } from 'hooks/auth/use-yandex-login';

import Error from 'pages/_error';

import { YandexAuthWidget } from 'components/auth-external/yandex/yandex-auth-widget';
import { FlatListPage } from 'components/flat/flat-list-page/flat-list-page';
import { FlatMapPage } from 'components/flat/flat-map-page/flat-map-page';
import { Page } from 'components/page/page';

interface FlatsPageProps {
  context: PageContext;
  query: SearchPageParams;
  accept: string;
  isMobileDevice: boolean;
  isMap: boolean;
  cookies: string;
  errorCode?: Optional<number>;
  yandexMetricaData: Answer;
}

interface CustomRequest extends IncomingMessage {
  originalUrl?: string;
  cookies?: Record<string, string>;
}

const SearchPage: React.FunctionComponent<FlatsPageProps> = (props) => {
  if (props.errorCode) {
    return <Error statusCode={props.errorCode} />;
  }
  if (!props.context) {
    return null;
  }
  const mainHost = props.context.common.host ? props.context.common.host : '';
  const currentPath = props.context.extra.path || '';
  const bookmarksList = props.context.extra.bookmarksList || [];
  const correlationID = get(props.context, 'req.headers.x-request-id', '') as string;
  const router = useRouter();

  let city = null;
  if (props.context.extra.meta.filters.city) {
    city = props.context.extra.meta.filters.city;
  }
  if (props.context.extra.city) {
    city = props.context.extra.city;
  }

  const onLogin = () => {
    router.reload();
  };

  const onYandexAuth = useLoginYandex(mainHost, correlationID, onLogin);

  const isMapRedirected = Boolean(router.query.fromMap);
  const isEmptyFlatList = !props.context.extra.flats.length;

  const searchOptions = {
    searchFilters: props.query,
    apiFilters: props.context.extra.meta.filters,
    isLanding: Boolean(props.context.extra.meta.isLanding),
    meta: props.context.extra.meta,
    city
  };

  return (
    <Page
      ctx={props.context.common}
      {...(!isEmptyFlatList || isMapRedirected ? { searchOptions } : {})}
      noFooter={props.isMap}
      noIndex={currentPath.includes('/page')}
      withoutLoading={true}
    >
      <MetricaExperimentsContext.Provider value={{ ...props.yandexMetricaData, ready: true }}>
        <FlatCalendarProvider>
          <>
            {props.isMap ? (
              <FlatMapPage
                flats={props.context.extra.flats}
                bookmarksList={bookmarksList}
                city={city}
                meta={props.context.extra.meta}
                accept={props.accept}
                mainHost={mainHost}
                isMobileDevice={props.isMobileDevice}
                cookies={props.cookies}
                isLanding={props.context.extra.meta.isLanding}
                currentCurrencyId={props.context.common.currencies.current.id}
              />
            ) : (
              <SearchFlatListProvider
                initialState={getSearchFlatListInitialState({
                  meta: props.context.extra.meta,
                  flats: props.context.extra.flats
                })}
              >
                <FlatListPage
                  meta={props.context.extra.meta}
                  seo={props.context.common.seo}
                  bookmarksList={bookmarksList}
                  query={props.query}
                  path={currentPath}
                  accept={props.accept}
                  mainHost={mainHost}
                  isMobileDevice={props.isMobileDevice}
                  city={props.context.extra.city}
                  currency={props.context.common.currencies.current}
                  cookies={props.cookies}
                />
              </SearchFlatListProvider>
            )}

            {props.yandexMetricaData.flags.yandexWidget?.[0] && !props.context.common.user?.id ? (
              <YandexAuthWidget yandexIdClientId={props.context.common.yandexIdClientId} onAuth={onYandexAuth} />
            ) : null}
          </>
        </FlatCalendarProvider>
      </MetricaExperimentsContext.Provider>
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res, req } = context;
  const params: Array<string> = get(context, 'params.search', []) as Array<string>;
  const isMap = params.slice().pop() === 'map';

  const path = `/${params.join('/')}/`;
  const mainHost = process.env.NEXT_PUBLIC_MAIN_HOST || '';
  const correlationID = get(context, 'req.headers.x-request-id', '') as string;
  const cookies = get(context, 'req.headers.cookie', '');
  const accept = get(context, 'req.headers.accept', '');
  const UA = context.req.headers['user-agent'];
  const isMobileDevice = Boolean(UA?.match(MOBILE_USER_AGENTS));

  const protocol = (req.headers['x-forwarded-proto'] as string) || 'http';
  const url = new URL(req.url || '', `${protocol}://${mainHost}`);
  const сookieYmabParam = req.cookies._ymab_param || '';

  const yandexMetricaData =
    (await getYandexMetricaAbt(req as CustomRequest, res, YANDEX_AB_METRIKA, сookieYmabParam, url.toString())) || {};

  const { newCalendar } = yandexMetricaData.flags;

  const query = getQueryParamsWithUrl(context);

  if (path === '/') {
    res.writeHead(301, { location: '/' });
    res.end();
  }

  const options: PageContextOptions<SearchPageContext> = {
    path,
    host: mainHost,
    correlationID,
    cookies,
    authRequired: false,
    query,
    isExtraUseCurrency: true,
    extra: async (accessToken, currentCurrencyId): Promise<SearchPageContext> => {
      if (isMap) {
        return await getFlatMapPageContext({
          mainHost,
          accept,
          correlationID,
          accessToken,
          cookies,
          path,
          context,
          isMobileDevice,
          ua: UA,
          currentCurrencyId: currentCurrencyId || RUS_ID
        });
      }
      return await getFlatListPageContext({
        mainHost,
        accept,
        correlationID,
        accessToken,
        cookies,
        path,
        context,
        ua: UA,
        newCalendar,
        currentCurrencyId: currentCurrencyId || RUS_ID
      });
    }
  };

  let pageContext = null;
  let errorCode = null;

  try {
    pageContext = await fetchPageContext<SearchPageContext>(options);
  } catch (err) {
    await catchErrors({ err, res, mainHost, accept, isMap, params, path, query });
    errorCode = get(err, 'response.status', 0);
  }

  if (errorCode) {
    res.statusCode = errorCode;

    if (errorCode === 404) {
      return {
        notFound: true
      };
    }

    if (!pageContext) {
      return {
        props: {
          errorCode
        }
      };
    }
  }

  if (pageContext) {
    if (pageContext.common['set-cookie']) {
      pageContext.common['set-cookie'].map((cookie) => {
        res.setHeader('Set-Cookie', cookie);
      });
    }

    redirectFromMap(isMap, pageContext as PageContext, res, path, query, params);
    await redirectFromEmptyPaginationPage(mainHost, accept, pageContext as PageContext, path, query, res);
    redirectFromEmptyLandingPage(pageContext as PageContext, path, query, res);
  }

  return {
    props: {
      context: pageContext,
      query: query,
      isMobileDevice,
      accept,
      isMap: DISABLE_MAP ? false : isMap,
      cookies,
      yandexMetricaData
    }
  };
};

export default SearchPage;
