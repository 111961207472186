import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { SearchPageParams } from 'core/entities/search';
import { getQueryParamsFromUrl } from 'core/utils/filters/search-filter';

export const useSearchQuery = (defaultQuery: SearchPageParams = {}) => {
  const clearParams = omit(defaultQuery, ['search', 'utm_url', 'referer']);

  const [query, setQuery] = useState(clearParams);
  const { asPath } = useRouter();

  useEffect(() => {
    const searchUrl = asPath.split('?')[1];

    if (searchUrl) {
      setQuery(() => ({ ...getQueryParamsFromUrl(searchUrl) }));
    } else {
      setQuery(clearParams);
    }
  }, [asPath]);

  return { query };
};
