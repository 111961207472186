import { useContext } from 'react';

import { DEFAULT_CURRENCY } from 'core/constants/default-values';

import { CommonContext } from 'contexts/common';

export const useCurrency = (currencyCode?: number) => {
  const { context } = useContext(CommonContext);
  if (context) {
    if (currencyCode) {
      const currency = context.currencies.entries.filter((item) => item.id === currencyCode);

      if (currency) {
        return currency[0];
      }
    }

    return context.currencies.current;
  }
  return DEFAULT_CURRENCY;
};
