import styles from './_error.module.css';

import { GetServerSideProps } from 'next';

interface ErrorProps {
  statusCode: number;
}

const Error = ({ statusCode }: ErrorProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <img className={styles.img} src='/s/modern/logo/mobile/kvartirka-logo-4.png' alt='Квартирка' />
        <h3>У нас произошла какая-то ошибка ({statusCode})</h3>
        <p>Мы уже знаем о ней и скоро все починим. Пожалуйста, попробуйте зайти немного позже.</p>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/require-await
export const getServerSideProps: GetServerSideProps = async (context) => {
  const statusCode = context.res ? context.res.statusCode : 404;
  return {
    props: {
      statusCode
    }
  };
};

export default Error;
