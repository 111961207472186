/* eslint-disable max-params */
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { City, Point } from 'core/entities/geo';
import { SearchPageParams } from 'core/entities/search';
import { CitiesService } from 'core/services/cities';
import { SearchPageContext } from 'core/services/context/context';
import { APIService } from 'core/services/index';

export class SearchPageService extends APIService {
  private readonly cookies: Optional<string>;
  private abortController: Optional<AbortController>;
  private ua: Optional<string>;
  private currentCurrencyId: Optional<number>;

  constructor(
    host: string,
    currentCurrencyId: number,
    correlationID: Optional<string> = null,
    accessToken: Optional<string> = null,
    cookies: Optional<string> = null,
    ua: Optional<string> = null
  ) {
    super(host, correlationID, accessToken);
    this.cookies = cookies;
    this.abortController = null;
    this.ua = ua;
    this.currentCurrencyId = currentCurrencyId;
  }

  citiesService = new CitiesService(this.host, this.correlationID);
  city: Optional<City> = null;

  public async fetchFlatsByPath(
    accept: string,
    query: SearchPageParams,
    path: string,
    limit: number,
    offset?: number,
    nearest?: number,
    newCalendar?: string
  ) {
    const citySlug = path.split('/')[2];
    if (citySlug) {
      const cityResponse = await this.citiesService.fetchBySlug(citySlug);
      this.city = cityResponse.cities[0];
    }
    this.cancelRequests();
    const response = await this.execute('/api/v3/context/search', 'GET', {
      params: {
        currency: this.currentCurrencyId,
        ...omit(query, ['zoom', 'pointName']),
        path,
        limit,
        offset,
        nearest,
        newCalendar
      },
      customHeaders: {
        Accept: accept,
        Cookie: this.cookies,
        ['User-Agent']: this.ua
      },
      controller: this.abortController
    });

    return this.processResponse(response, this.city);
  }

  public async fetchFlatsByBox(
    accept: string,
    mapParams: {
      point: Point;
      box: number[][];
      boundaryKey?: number;
    },
    query: SearchPageParams,
    limit: number,
    offset?: number,
    nearest?: number,
    newCalendar?: string
  ): Promise<SearchPageContext> {
    const cityResponse = await this.citiesService.fetchByCoords(mapParams.point.lat, mapParams.point.lng);
    this.city = cityResponse.cities[0];
    this.cancelRequests();
    const response = await this.execute('/api/v3/context/search', 'GET', {
      params: {
        currency: this.currentCurrencyId,
        ...omit(query, ['lat', 'lng', 'zoom', 'pointName']),
        minLat: mapParams.box[0][0],
        minLng: mapParams.box[0][1],
        maxLat: mapParams.box[1][0],
        maxLng: mapParams.box[1][1],
        limit,
        offset,
        nearest,
        newCalendar,
        boundaryKey: mapParams.boundaryKey
      },
      customHeaders: {
        Accept: accept,
        Cookie: this.cookies,
        ['User-Agent']: this.ua
      },
      controller: this.abortController
    });
    return this.processResponse(response, this.city);
  }

  public async fetchFlatsByCenter(
    accept: string,
    point: Point,
    query: SearchPageParams,
    limit: number,
    offset?: number,
    nearest?: number,
    newCalendar?: string,
    boundaryKey?: number
  ): Promise<SearchPageContext> {
    const cityResponse = await this.citiesService.fetchByCoords(point.lat, point.lng);
    this.city = cityResponse.cities[0];
    this.cancelRequests();

    const response = await this.execute('/api/v3/context/search', 'GET', {
      params: {
        currency: this.currentCurrencyId,
        ...omit(query, ['minLat', 'minLng', 'maxLat', 'maxLng', 'zoom', 'pointName']),
        lat: point.lat,
        lng: point.lng,
        limit,
        offset,
        nearest,
        newCalendar,
        boundaryKey
      },
      customHeaders: {
        Accept: accept,
        Cookie: this.cookies,
        ['User-Agent']: this.ua
      },
      controller: this.abortController
    });
    return this.processResponse(response, this.city);
  }

  public cancelRequests() {
    this.abortController?.abort();
    this.abortController = new AbortController();
  }

  private processResponse(response: AxiosResponse, city?: Optional<City>): SearchPageContext {
    return {
      meta: get(response, 'data.meta', {}),
      flats: get(response, 'data.flats', []),
      city: city ? city : null
    };
  }
}
