import styles from './flat-list-footer-linking.module.css';

import { SyntheticEvent, useCallback, useState } from 'react';

import CustomLink from 'components/common/link/link';
import { Text } from 'components/common/text/text';

interface FlatListFooterLinkingItemProps {
  item: filteredNearestCitiesType;
  hidden?: boolean;
}

interface FlatListFooterLinkingItemsProps {
  items: filteredNearestCitiesType[];
  name: string;
}

interface filteredNearestCitiesType {
  text?: string;
  url?: string;
}

const FlatListFooterLinkingItem = ({ item, hidden }: FlatListFooterLinkingItemProps) => {
  const classNameLi = hidden ? styles.hidden : styles.item;
  return (
    <li className={classNameLi}>
      <CustomLink href={item.url || ''} variant='black' underlined external>
        {item.text || ''}
      </CustomLink>
    </li>
  );
};

export const FlatListFooterLinkingItems = ({ items, name }: FlatListFooterLinkingItemsProps) => {
  const limit = 20;
  const withLimit = items.length >= 30;
  const [showMore, setShowMore] = useState(withLimit);

  const handleShowMore = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    setShowMore(false);
  }, []);

  return (
    <div className={styles.root}>
      <Text As='h3' size={16}>
        {name}
      </Text>
      <ul className={styles.list}>
        {items.map((item, ind) => (
          <FlatListFooterLinkingItem key={item.url} item={item} hidden={withLimit && ind >= limit && showMore} />
        ))}
        {showMore && (
          <li className={styles.item} onClick={handleShowMore}>
            <span className={styles.more}>Показать еще...</span>
          </li>
        )}
      </ul>
    </div>
  );
};
