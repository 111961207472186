import styles from './search-flat-modal.module.css';

import { useEffect, useState } from 'react';

import { Currency } from 'core/entities';
import { Filters } from 'core/entities/filters';
import { CardFlat } from 'core/entities/flats';

import { useRetinaDisplay } from 'hooks/photos/use-retina-display';
import { useOnClickEscape } from 'hooks/use-on-click-escape';

import { FlatCardMap } from 'components/flat/flat-card/flat-card-map';

interface SearchFlatModalProps {
  mainHost: string;
  filters: Filters;
  flat: CardFlat;
  bookmarksList: Array<number>;
  currency: Currency;
  onClose: () => void;
  isMobileDevice: boolean;
  duration?: Optional<number>;
  onFlatClick?: (flat: CardFlat) => void;
}

export const SearchFlatModal = (props: SearchFlatModalProps) => {
  const [open, setOpen] = useState(false);
  const isRetina = useRetinaDisplay();

  const handleFlatClick = () => {
    if (props.onFlatClick) {
      props.onFlatClick(props.flat);
    }
  };

  useEffect(() => {
    if (props.flat) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.flat]);

  useOnClickEscape(props.onClose);

  if (!open) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <button className={styles.closeBtn} onClick={props.onClose}>
          <div className={styles.closeBtnIcon} />
        </button>
        <FlatCardMap
          mainHost={props.mainHost}
          flat={props.flat}
          bookmarksList={props.bookmarksList}
          currency={props.currency}
          duration={props.duration}
          isRetina={isRetina}
          isMobileDevice={props.isMobileDevice}
          onFlatClick={handleFlatClick}
        />
      </div>
    </div>
  );
};
