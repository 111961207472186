import styles from './infinity-flat-list.module.css';

import classNames from 'classnames';
import { useContext, useEffect, useRef } from 'react';

import { Currency } from 'core/entities';
import { CardFlat } from 'core/entities/flats';
import { City } from 'core/entities/geo';
import { SearchPageParams } from 'core/entities/search';
import { SeoCards } from 'core/entities/seo';
import { VisibilityService } from 'core/services/visibility';

import { FilterControlsContext } from 'contexts/filters/filter-control';
import { FlatCalendarContext } from 'contexts/filters/filter-flat-calendar';
import { FiltersContext } from 'contexts/filters/filters';
import { useSearchFlatList } from 'contexts/search/search-flat-list';

import { useHasChekedFilters } from 'hooks/filters';
import { InfinityListHook } from 'hooks/flat-list/infinity-list';
import { CalendarForFlatsListHook } from 'hooks/flat-list/use-calendar-for-flats';
import { useVisibility } from 'hooks/use-visibility';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import Link from 'components/common/link/link';
import { Text, TextColors } from 'components/common/text/text';
import { FlatCalendarModal } from 'components/flat/flat-calendar-modal/flat-calendar-modal';
import { FlatList } from 'components/flat/flat-list/flat-list';
import { FlatListLoadMore } from 'components/flat/infinity-flat-list/flat-list-load-more/flat-list-load-more';
import { UnavailableFlatModal } from 'components/flat/unavailable-flat-modal/unavailable-flat-modal';

interface InfinityFlatListProps {
  mainHost: string;
  accept: string;
  path: string;
  seoCards: SeoCards;
  noticeTop: string;
  starredIdList: Array<number>;
  defaultQuery: SearchPageParams;
  currency: Currency;
  isMobileDevice: boolean;
  cookies: string;
  calendarHook: CalendarForFlatsListHook;
  infinityListHook: InfinityListHook;
  city?: Optional<City>;
}

export const InfinityFlatList = (props: InfinityFlatListProps) => {
  const { filters, adultChecked } = useContext(FiltersContext);
  const { calendar, guestsModal, guestsDropdown } = useContext(FilterControlsContext);
  const { selectedFlatCalendar } = useContext(FlatCalendarContext);

  const { isSmallDesktop, isDesktop } = useBreakpoints();
  const { filtersApplied, hasChekedCalendar, hasChekedGuests } = useHasChekedFilters(
    filters,
    props.currency,
    adultChecked
  );

  const { meta, flats } = useSearchFlatList();
  // пока показ календаря flatCalendar вкл всегда, независимо от флагов метрики
  // const { flatCalendar } = useMetrikaExperiments();
  const flatCalendar = true;

  const visibilityService = useRef(new VisibilityService(props.mainHost));

  const { handleVisibilityChange, startVisibilityWatcher, stopVisibilityWatcher } = useVisibility(
    filtersApplied,
    (props.city || meta.filters.city) as City
  );

  const onFlatClick = (flat: CardFlat) => {
    if (props.calendarHook.isRefusedSelectDates) {
      window.open(flat.url, '_blank');

      return;
    }

    selectedFlatCalendar.onOpen();
    props.calendarHook.handleFlatClick(flat);
  };

  useEffect(() => {
    startVisibilityWatcher(visibilityService.current);
    return () => {
      stopVisibilityWatcher(visibilityService.current);
    };
  }, [filters]);

  return (
    <div className={styles.root}>
      <FlatList
        className={classNames({ [styles.withPreview]: props.infinityListHook.showLoadButton })}
        flatList={flats}
        mainHost={props.mainHost}
        isMobileDevice={props.isMobileDevice}
        city={props.city || meta.filters.city}
        starredIdList={props.starredIdList}
        dateDuration={filters.dates.duration}
        seoCards={props.seoCards}
        withPreview={props.infinityListHook.showLoadButton}
        filtersApplied={filtersApplied}
        showCalendarFilter={!hasChekedCalendar}
        onCalendarFilterClick={calendar.onOpen}
        onFlatClick={flatCalendar ? onFlatClick : null}
        showGuestsFilter={!hasChekedGuests}
        onGuestsFilterClick={isDesktop || isSmallDesktop ? guestsDropdown.onOpen : guestsModal.onOpen}
        onVisibilityChange={handleVisibilityChange}
        showSkeleton={props.infinityListHook.showSkeleton}
      />
      <ErrorInfo
        errorValue={props.infinityListHook.errorValue}
        isLoading={props.infinityListHook.isLoading}
        flatsLength={flats.length}
        filtersApplied={filtersApplied}
        city={props.city || meta.filters.city}
        noticeTop={props.noticeTop}
      />
      {props.infinityListHook.showLoadButton && !props.infinityListHook.showSkeleton && (
        <FlatListLoadMore onClick={props.infinityListHook.fetchFlats} />
      )}

      <FlatCalendarModal mainHost={props.mainHost} calendarHook={props.calendarHook} />
      <UnavailableFlatModal calendarHook={props.calendarHook} />
    </div>
  );
};

interface ErrorInfoProps {
  errorValue: string;
  isLoading: boolean;
  flatsLength: number;
  filtersApplied?: boolean;
  city?: Optional<City>;
  noticeTop: string;
}

function ErrorInfo(props: ErrorInfoProps) {
  if (!props.errorValue && !props.isLoading && props.flatsLength === 0 && props.noticeTop === '') {
    if (props.filtersApplied) {
      return <Text size={16}>Расширьте область карты или измените параметры поиска.</Text>;
    }
    return (
      <>
        <Text size={16} As='p'>
          Сейчас объектов {props.city && props.city.namePrep ? `в ${props.city.namePrep}` : ''} нет.
        </Text>
        <Text size={16} As='p'>
          <Link href='/account/registration/' external={true}>
            Добавьте своё объявление
          </Link>{' '}
          о сдаче жилья.
        </Text>
      </>
    );
  }
  if (props.errorValue && !props.isLoading) {
    return (
      <Text size={16} color={TextColors.error}>
        {props.errorValue}
      </Text>
    );
  }
  return null;
}
