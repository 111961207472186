/* eslint-disable no-undefined */
import styles from './search-map.module.css';

import * as Sentry from '@sentry/nextjs';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ymaps from 'yandex-maps';

import { DEFAULT_CURRENCY, DEFAULT_LOADINGS } from 'core/constants/default-values';
import { LIMIT_FOR_MAP_FLATS_DESKTOP, LIMIT_FOR_MAP_FLATS_MOBILE } from 'core/constants/flats';
import { MAP_SETTINGS } from 'core/constants/map-settings';
import { CardFlat } from 'core/entities/flats';
import { City } from 'core/entities/geo';
import { SearchMeta } from 'core/entities/search';
import { LocationContext, SearchPageContext } from 'core/services/context/context';
import { SearchPageService } from 'core/services/context/search';
import { LocationService } from 'core/services/context/search/location';
import { getFiltersWithoutMapParams } from 'core/utils/filters/search-filter';

import { CommonContext } from 'contexts/common';
import { FlatCalendarContext } from 'contexts/filters/filter-flat-calendar';
import { FiltersContext } from 'contexts/filters/filters';
import { MapStarredContext } from 'contexts/map-starred';
import { SearchBarContext, SearchBarMode } from 'contexts/search/search-bar';

import { useCalendarForFlatsList } from 'hooks/flat-list/use-calendar-for-flats';
import { useLockBodyScroll } from 'hooks/scroll/use-lock-body-scroll';
import { useApproximatePrice } from 'hooks/use-approximate-price';
import { useMetrikaExperiments } from 'hooks/use-metrika-experiments';
import { useSearchQuery } from 'hooks/use-search-query';
import { addDotsToObjectManager, addFlatsToObjectManager, useSearchYmaps } from 'hooks/use-search-ymaps';

import { FlatCalendarModal } from 'components/flat/flat-calendar-modal/flat-calendar-modal';
import { UnavailableFlatModal } from 'components/flat/unavailable-flat-modal/unavailable-flat-modal';
import { SearchFlatModal } from 'components/search-map/search-flat-modal/search-flat-modal';
import { SearchInfo } from 'components/search-map/search-info/search-info';

interface SearchMapProps {
  flats: Array<CardFlat>;
  meta: SearchMeta;
  accept: string;
  mainHost: string;
  isMobileDevice: boolean;
  cookies: Optional<string>;
  currentCurrencyId: number;
  city?: Optional<City>;
  isLanding?: boolean;
}

const mapOptions: ymaps.IMapOptions = {
  maxZoom: MAP_SETTINGS.maxZoom,
  minZoom: MAP_SETTINGS.minZoom,
  avoidFractionalZoom: false,
  yandexMapDisablePoiInteractivity: true
};

export const SearchMap = (props: SearchMapProps) => {
  const { filters, setFilters, mapShifted, setMapShifted, setLoading: setFiltersLoading } = useContext(FiltersContext);
  const searchBarContext = useContext(SearchBarContext);
  const { bookmarksList } = useContext(MapStarredContext);
  const { context } = useContext(CommonContext);
  const { selectedFlatCalendar, unavailableFlatModal } = useContext(FlatCalendarContext);
  const { query } = useSearchQuery();
  const { setLocked } = useLockBodyScroll();

  // пока показ календаря flatCalendar вкл всегда, независимо от флагов метрики
  // const { flatCalendar, newCalendar } = useMetrikaExperiments();
  const { newCalendar } = useMetrikaExperiments();
  const flatCalendar = true;

  const [filtersWithoutMapParams, setFiltersWithoutMapParams] = useState(getFiltersWithoutMapParams(filters));

  const calendarHook = useCalendarForFlatsList(props.mainHost);
  const onFlatClick = (flat: CardFlat) => {
    if (calendarHook.isRefusedSelectDates || !flatCalendar) {
      window.open(flat.url, '_blank');

      return;
    }

    selectedFlatCalendar.onOpen();
    calendarHook.handleFlatClick(flat);
  };

  const [totalFlats, setTotalFlats] = useState(props.meta.total);
  const [selectedFlat, setSelectedFlat] = useState<Optional<CardFlat>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorValue, setErrorValue] = useState('');

  const currency = context ? context.currencies.current : DEFAULT_CURRENCY;

  const { showApproximatePrice } = useApproximatePrice();

  const getCenter = useCallback(() => {
    if (filters.map.lat && filters.map.lng) {
      return [filters.map.lat, filters.map.lng];
    }
    if (props.meta.filters.point) {
      return [props.meta.filters.point.lat, props.meta.filters.point.lng];
    }
    const region = props.meta.filters.regions ? props.meta.filters.regions[0] : null;
    const country = props.meta.filters.country;
    if (props.city) {
      return [props.city.center.lat, props.city.center.lng];
    }
    if (region) {
      return [region.center.lat, region.center.lng];
    }
    if (country) {
      return [country.center.lat, country.center.lng];
    }
    return [0, 0];
  }, []);

  const getZoom = useCallback(() => {
    let zoom = MAP_SETTINGS.zoomForCity;
    if (props.isLanding && props.meta.filters.point) {
      zoom = MAP_SETTINGS.zoomForPlace;
    }
    if (filters.map.zoom) {
      zoom = filters.map.zoom;
    }
    return zoom;
  }, [filters]);

  const getBounds = useCallback(() => {
    let bounds;
    if (props.meta.bbox) {
      bounds = [
        [props.meta.bbox.pointMin.lat, props.meta.bbox.pointMin.lng],
        [props.meta.bbox.pointMax.lat, props.meta.bbox.pointMax.lng]
      ];
    }
    return bounds;
  }, []);

  let stateCenter;
  let stateZoom;
  if (!props.meta.bbox) {
    stateCenter = getCenter();
    stateZoom = getZoom();
  }

  const mapState: ymaps.IMapState = {
    center: stateCenter,
    zoom: stateZoom,
    bounds: getBounds(),
    controls: []
  };

  const mapRef = useRef<HTMLDivElement>(null);
  const searchPageService = useRef(
    new SearchPageService(props.mainHost, props.currentCurrencyId, null, null, props.cookies)
  );
  const locationService = useRef(new LocationService(props.mainHost, null, null, props.cookies));
  const requestTimer = React.useRef<Optional<NodeJS.Timeout>>(null);

  const handleCloseModal = useCallback(() => {
    setLocked(false);
    resetSelectedObject();
    setSelectedFlat(null);
    resetActiveElement();
  }, []);

  const handleMoveMap = useCallback(() => {
    setMapShifted(true);
    searchBarContext.setMode(SearchBarMode.MAP_AREA);
    searchBarContext.setSavedValue('');
    searchBarContext.setSuggests([]);
  }, []);

  const {
    box,
    center,
    setCenter,
    mapInstance,
    dotObjectManager,
    mapObjectManager,
    selectedObject,
    resetSelectedObject,
    yandexMap,
    resetActiveElement
  } = useSearchYmaps({
    mapRef,
    flats: props.flats,
    dots: props.flats,
    state: mapState,
    options: mapOptions,
    mapFilters: filters.map,
    currency,
    bookmarksList: bookmarksList,
    duration: filters.dates.duration,
    bbox: props.meta.bbox,
    onCloseModal: handleCloseModal,
    onMoveMap: handleMoveMap,
    showApproximatePrice
  });

  useEffect(() => {
    if (selectedObject && selectedObject.options) {
      setLocked(true);
      setSelectedFlat(selectedObject.options.flat);
      searchBarContext.setShowFilters(false);
    }
  }, [selectedObject]);

  useEffect(() => {
    if (searchBarContext.showFilters) {
      handleCloseModal();
    }
  }, [searchBarContext.showFilters]);

  useEffect(() => {
    if (!unavailableFlatModal.opened) {
      handleCloseModal();
    }
  }, [unavailableFlatModal.opened]);

  const fetchFlats = useCallback(async () => {
    if (box && center && mapInstance.current) {
      try {
        setIsLoading(true);
        setErrorValue('');
        const requests = [
          new Promise((resolve) => {
            resolve(
              searchPageService.current.fetchFlatsByBox(
                props.accept,
                {
                  point: {
                    lat: center[0],
                    lng: center[1]
                  },
                  box,
                  boundaryKey: props.meta.filters.boundaryKey
                },
                query,
                props.isMobileDevice ? LIMIT_FOR_MAP_FLATS_MOBILE : LIMIT_FOR_MAP_FLATS_DESKTOP,
                undefined,
                undefined,
                newCalendar
              )
            );
          }),
          new Promise((resolve) => {
            resolve(
              locationService.current.fetchDotsByBox(
                {
                  lat: center[0],
                  lng: center[1]
                },
                box,
                query,
                newCalendar,
                props.meta.filters.boundaryKey
              )
            );
          })
        ];
        const response = (await Promise.all(requests)) as [SearchPageContext, LocationContext];
        const flats = response[0];
        const dots = response[1];

        if (mapObjectManager.current && dotObjectManager.current && yandexMap) {
          setTotalFlats(flats.meta.total + dots.meta.total);
          addDotsToObjectManager(dotObjectManager.current, yandexMap, dots.flats);
          addFlatsToObjectManager(
            mapObjectManager.current,
            yandexMap,
            flats.flats,
            currency.entity,
            bookmarksList,
            filters.dates.duration,
            showApproximatePrice
          );
        }
      } catch (err) {
        setErrorValue(String(err));
        Sentry.captureException(err);
      } finally {
        setFiltersLoading(DEFAULT_LOADINGS);
        setIsLoading(false);
      }
    }
  }, [query]);

  const searchFlats = useCallback(() => {
    if (requestTimer.current) {
      clearTimeout(requestTimer.current);
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    requestTimer.current = setTimeout(fetchFlats, 1250);
  }, [fetchFlats, requestTimer]);

  useEffect(() => {
    if (!isEqual(getFiltersWithoutMapParams(filters), filtersWithoutMapParams)) {
      setFiltersWithoutMapParams(getFiltersWithoutMapParams(filters));
    }
  }, [query]);

  useEffect(() => {
    if (mapObjectManager.current && dotObjectManager.current) {
      mapObjectManager.current.removeAll();
      dotObjectManager.current.removeAll();
    }
  }, [filtersWithoutMapParams]);

  useEffect(() => {
    if (center && box && mapInstance.current) {
      const zoom = mapInstance.current.getZoom();
      const updatedMap = {
        lat: center[0],
        lng: center[1],
        minLat: box[0][0],
        minLng: box[0][1],
        maxLat: box[1][0],
        maxLng: box[1][1],
        zoom
      };

      setFilters((prev) => ({ ...prev, map: { ...prev.map, ...updatedMap } }));
    }
  }, [box, filtersWithoutMapParams]);

  useEffect(() => {
    if (mapInstance.current) {
      const mapCenter = mapInstance.current.getCenter();

      setCenter(mapCenter);
      setFilters((prev) => ({ ...prev, map: { ...prev.map, lat: mapCenter[0], lng: mapCenter[1] } }));
    }
  }, [filtersWithoutMapParams]);

  useEffect(() => {
    if (mapShifted) {
      setFilters((prev) => ({
        ...prev,
        pointName: ''
      }));
    }
  }, [mapShifted]);

  useEffect(() => {
    if (mapInstance.current && filters.map.lng && filters.map.lat && filters.map.zoom) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      mapInstance.current.setCenter([filters.map.lat, filters.map.lng], filters.map.zoom);
    }
  }, [filters]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    searchFlats();
  }, [searchFlats]);

  return (
    <div className={styles.root}>
      {selectedFlat ? (
        <SearchFlatModal
          mainHost={props.mainHost}
          filters={filters}
          flat={selectedFlat}
          bookmarksList={bookmarksList}
          currency={currency}
          duration={filters.dates.duration}
          onClose={handleCloseModal}
          isMobileDevice={props.isMobileDevice}
          onFlatClick={onFlatClick}
        />
      ) : (
        <SearchInfo
          className={styles.info}
          mainHost={props.mainHost}
          filters={filters}
          meta={props.meta}
          totalFlats={totalFlats}
          mapShifted={mapShifted}
          loading={isLoading}
          errorValue={errorValue}
        />
      )}
      <div className={styles.map} ref={mapRef} />

      <FlatCalendarModal mainHost={props.mainHost} calendarHook={calendarHook} />
      <UnavailableFlatModal calendarHook={calendarHook} />
    </div>
  );
};
