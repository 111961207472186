import { useCallback, useContext, useEffect, useState } from 'react';

import { CALENDAR_MODAL_CONTINUE_BUTTON_TEXT } from 'core/constants/filters';
import { BookingInterval } from 'core/entities/booking';

import { FlatCalendarContext } from 'contexts/filters/filter-flat-calendar';
import { FiltersContext } from 'contexts/filters/filters';

import { CalendarForFlatsListHook } from 'hooks/flat-list/use-calendar-for-flats';

import { CalendarFooterWithSkip } from 'components/common/calendar-footer-with-skip/calendar-footer-with-skip';
import { CalendarModal } from 'components/search/controls/calendar/calendar-modal/calendar-modal';

interface FlatCalendarModalProps {
  mainHost: string;
  calendarHook: CalendarForFlatsListHook;
}

export const FlatCalendarModal = ({ mainHost, calendarHook }: FlatCalendarModalProps) => {
  const { filters, setFilters, setLoading: setFiltersLoading } = useContext(FiltersContext);
  const { selectedFlatCalendar } = useContext(FlatCalendarContext);

  const [dates, setDates] = useState(filters.dates);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [calendarError, setCalendarError] = useState<Optional<string>>(null);

  const onCalendarApply = useCallback(() => {
    calendarHook.setSelectedDates(dates);
    calendarHook.handleFlatPickWithDates(dates);
  }, [dates, calendarHook.setSelectedDates, calendarHook.handleFlatPickWithDates]);

  const onDurationChange = useCallback(
    async (range: BookingInterval) => {
      await calendarHook.checkFlat(range, setIsBtnLoading);
    },
    [calendarHook.checkFlat]
  );

  const onSkip = () => {
    calendarHook.setSelectedDates(null);
    calendarHook.setIsRefusedSelectDates(true);
    selectedFlatCalendar.onClose();

    if (calendarHook.selectedFlat) {
      window.open(calendarHook.selectedFlat.url, '_blank');
    }
  };

  const footerChildren = <CalendarFooterWithSkip calendarError={calendarError} dates={dates} onSkip={onSkip} />;

  useEffect(() => {
    if (dates.duration) {
      setIsBtnDisabled(false);
      setIsBtnLoading(true);
    } else {
      setIsBtnDisabled(true);
      setIsBtnLoading(false);
    }
  }, [dates.duration]);

  return (
    <CalendarModal
      btnText={CALENDAR_MODAL_CONTINUE_BUTTON_TEXT}
      calendar={selectedFlatCalendar}
      calendarError={calendarError}
      dates={dates}
      mainHost={mainHost}
      filters={filters}
      footerChildren={footerChildren}
      setCalendarError={setCalendarError}
      setDates={setDates}
      withSkipBtn
      setFilters={setFilters}
      setLoading={setFiltersLoading}
      onApply={onCalendarApply}
      onChange={onDurationChange}
      isBtnDisabled={isBtnDisabled}
      isBtnLoading={isBtnLoading}
    />
  );
};
