import styles from './landing-list.module.css';

import { useMemo } from 'react';

import { FLATLIST_TEXT_LANDINGLIST_TITLE, LIMIT_OF_FLATS } from 'core/constants/flat-list';
import { Landing } from 'core/entities/search';

import { FlatListFooterLinkingItems } from 'components/common/flat-list-footer-linking/flat-list-footer-linking';

interface LandingListProps {
  landings: Array<Landing>;
  totalFlats: number;
}

interface filteredLandingType {
  text?: string;
  url?: string;
}

interface groupedLandingsType {
  name: string;
  priority: number;
  items: filteredLandingType[];
}

interface groupedLandingsTypes {
  [key: string]: groupedLandingsType;
}

export const LandingList = ({ landings, totalFlats }: LandingListProps) => {
  let filteredLanding: groupedLandingsType[] = [];
  if (totalFlats > LIMIT_OF_FLATS) {
    filteredLanding = useMemo((): groupedLandingsType[] => {
      const groupedLandings: groupedLandingsTypes = landings.reduce((acc: groupedLandingsTypes, landing: Landing) => {
        const group = landing.group || FLATLIST_TEXT_LANDINGLIST_TITLE;
        // eslint-disable-next-line no-prototype-builtins
        if (!acc.hasOwnProperty(group)) {
          acc[group] = {
            name: group,
            priority: landing.priority || -1,
            items: []
          };
        }
        acc[group].items.push({
          text: landing.text,
          url: landing.url
        });
        return acc;
      }, {} as groupedLandingsTypes);
      const items = Object.values(groupedLandings).sort((a, b) => a.priority - b.priority);
      return items;
    }, [totalFlats]);
  }
  if (filteredLanding.length === 0) {
    return null;
  }
  return (
    <div className={styles.root}>
      {filteredLanding.map((item) => (
        <FlatListFooterLinkingItems key={item.name} items={item.items} name={item.name} />
      ))}
    </div>
  );
};
