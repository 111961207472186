import styles from './flat-list.module.css';

import classNames from 'classnames';
import Head from 'next/head';

import { SearchMeta } from 'core/entities/search';

interface PaginatorProps {
  path: string;
  meta: SearchMeta;
  limit: number;
  hidden: boolean;
}

export const Paginator = (props: PaginatorProps) => {
  const { path, meta, limit, hidden } = props;

  const currentPage = Math.ceil((meta.next.offset - meta.next.limit) / meta.next.limit) + 1;
  const minPage = currentPage - limit;
  const maxPage = currentPage + limit;
  const lastPage = Math.ceil(meta.total / meta.next.limit);

  const pages = Array(limit * 2)
    .fill(0)
    .map((x, y) => minPage + y)
    .filter((page) => page > 0 && page <= lastPage);
  const urls = [];

  if (minPage > 1) {
    urls.push(
      <a key={0} rel='prev' href={`${path}page${currentPage - limit - 1}/`}>
        {'< '}
      </a>
    );
  }
  for (const page of pages) {
    if (page === currentPage) {
      urls.push(<span key={page}>{page} </span>);
    } else {
      const relLink = getRelLink(page, currentPage);
      if (relLink) {
        urls.push(
          <a key={page} rel={relLink} href={`${path}page${page}/`}>
            {page}{' '}
          </a>
        );
      } else {
        urls.push(
          <a key={page} href={`${path}page${page}/`}>
            {page}{' '}
          </a>
        );
      }
    }
  }
  if (maxPage < lastPage) {
    urls.push(
      <a key={lastPage} href={`${path}page${currentPage + limit}/`}>
        {'>'}
      </a>
    );
  }

  return (
    <div className={classNames(styles.root, { [styles.hidden]: hasNoPaginator(meta, hidden) })}>
      <Head>
        {currentPage > 1 ? <link rel='prev' href={`${path}page${currentPage - 1}/`} /> : null}
        {currentPage < lastPage ? <link rel='next' href={`${path}page${currentPage + 1}/`} /> : null}
      </Head>
      <div>{urls}</div>
    </div>
  );
};

function getRelLink(page: number, currentPage: number) {
  let relLink = null;
  if (page - 1 === currentPage) {
    relLink = 'next';
  } else if (page + 1 === currentPage) {
    relLink = 'prev';
  }
  return relLink;
}

function hasNoPaginator(meta: SearchMeta, hidden: boolean) {
  return hidden || !meta || !meta.next || !meta.next.limit || meta.isLanding;
}
