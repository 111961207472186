import React from 'react';

import { CardFlat } from 'core/entities/flats';
import { SearchMeta } from 'core/entities/search';
import { NOOP } from 'core/utils/NOOP';

interface SearchFlatListState {
  defaultMeta: SearchMeta;
  meta: SearchMeta;
  flats: Array<CardFlat>;
  totalFlats: number;
  setFlats: (flats: Array<CardFlat>) => void;
  resetFlats: () => void;
  setMeta: (value: SearchMeta) => void;
}

const initialMeta = {
  next: { nearest: 0, limit: 0, offset: 0 },
  total: 0,
  filters: {},
  breadcrumbs: []
};
export const SearchFlatListContext = React.createContext<SearchFlatListState>({
  meta: initialMeta,
  defaultMeta: initialMeta,
  flats: [],
  totalFlats: 0,
  setFlats: NOOP,
  resetFlats: NOOP,
  setMeta: NOOP
});

enum Action {
  SET_META = 'SET_META',
  SET_FLATS = 'SET_FLATS',
  RESET_FLATS = 'RESET_FLATS'
}

type ActionType =
  | { type: Action.SET_META; payload: SearchMeta }
  | { type: Action.SET_FLATS; payload: Array<CardFlat> }
  | { type: Action.RESET_FLATS };

const reducer = (state: SearchFlatListState, action: ActionType): SearchFlatListState => {
  switch (action.type) {
    case Action.SET_META:
      return { ...state, meta: action.payload, totalFlats: action.payload.total + action.payload.next.nearest };
    case Action.SET_FLATS:
      return { ...state, flats: state.flats.concat(...action.payload) };
    case Action.RESET_FLATS:
      return { ...state, flats: [] };
    default:
      return state;
  }
};

interface SearchFlatListProviderProps {
  initialState: SearchFlatListState;
  children: React.ReactElement;
}

export const SearchFlatListProvider = (props: SearchFlatListProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, props.initialState);
  const providerValue: SearchFlatListState = {
    ...state,
    setFlats: React.useCallback((payload) => dispatch({ type: Action.SET_FLATS, payload }), []),
    resetFlats: React.useCallback(() => dispatch({ type: Action.RESET_FLATS }), []),
    setMeta: React.useCallback((payload) => dispatch({ type: Action.SET_META, payload }), [])
  };

  return <SearchFlatListContext.Provider value={providerValue}>{props.children}</SearchFlatListContext.Provider>;
};

export const useSearchFlatList = () => {
  return React.useContext(SearchFlatListContext);
};

interface Options {
  meta: SearchMeta;
  flats: Array<CardFlat>;
}

export const getSearchFlatListInitialState = (options: Options): SearchFlatListState => {
  return {
    meta: options.meta,
    defaultMeta: options.meta,
    totalFlats: options.meta.total + options.meta.next.nearest,
    flats: options.flats,
    setFlats: NOOP,
    resetFlats: NOOP,
    setMeta: NOOP
  };
};
