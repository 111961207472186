/* eslint-disable no-undefined */
import { ParsedUrlQuery } from 'querystring';

import * as Sentry from '@sentry/nextjs';
import { CanceledError } from 'axios';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { DEFAULT_LOADINGS } from 'core/constants/default-values';
import { LIMIT_FOR_LIST } from 'core/constants/flats';
import { FiltersLoadingType } from 'core/entities/filters';
import { SearchMeta, SearchPageParams } from 'core/entities/search';
import { SearchPageContext } from 'core/services/context/context';
import { SearchPageService } from 'core/services/context/search';
import { getBoxFromQuery, getPointFromQuery } from 'core/utils/filters/search-filter';

import { useSearchFlatList } from 'contexts/search/search-flat-list';

import { useMetrikaExperiments } from 'hooks/use-metrika-experiments';

interface Options {
  mainHost: string;
  path: string;
  cookies: string;
  accept: string;
  query: SearchPageParams;
  bottomOfListRef: React.RefObject<HTMLDivElement>;
  currentCurrencyId: number;
  boundaryKey?: number;
  setFiltersLoading: (loadings: FiltersLoadingType) => void;
}

export interface InfinityListHook {
  showSkeleton: boolean;
  isLoading: boolean;
  errorValue: string;
  showLoadButton: boolean;
  meta: SearchMeta;
  fetchFlats: (withOffset?: boolean) => Promise<void>;
}

export const useInfinityList = (options: Options): InfinityListHook => {
  const { query, setFiltersLoading } = options;

  const { meta, setMeta, flats, setFlats, resetFlats } = useSearchFlatList();
  const { newCalendar } = useMetrikaExperiments();

  const [isLoading, setIsLoading] = React.useState(false);
  const [showSkeleton, setShowSkeleton] = React.useState(false);
  const [errorValue, setErrorValue] = React.useState('');
  const [loadCounter, setLoadCounter] = React.useState(0);
  const [showLoadButton, setShowLoadButton] = React.useState(false);
  const [defaultQuery, setDefaultQuery] = React.useState(options.query);
  const [lastOffset, setLastOffset] = React.useState(0);

  const mounted = React.useRef(false);
  const searchPageService = React.useRef(
    new SearchPageService(options.mainHost, options.currentCurrencyId, null, null, options.cookies)
  );

  const getMeta = React.useCallback(() => {
    const currentPage = Math.ceil((meta.next.offset - meta.next.limit) / meta.next.limit) + 1;
    return {
      currentPage,
      offset: currentPage * LIMIT_FOR_LIST
    };
  }, [meta]);

  const fetchFlats = React.useCallback(
    async (withOffset = true, withReset = false) => {
      const { offset } = getMeta();
      setLastOffset(offset);
      if (offset === lastOffset) {
        return;
      }
      const currentOffset = withOffset ? offset : 0;
      const point = getPointFromQuery(query as ParsedUrlQuery);
      const box = getBoxFromQuery(query as ParsedUrlQuery);
      let response: SearchPageContext;

      try {
        setShowLoadButton(false);
        setErrorValue('');
        setIsLoading(true);

        if (point && !box) {
          response = await searchPageService.current.fetchFlatsByCenter(
            options.accept,
            point,
            query,
            LIMIT_FOR_LIST,
            currentOffset,
            undefined,
            newCalendar,
            options.boundaryKey
          );
        } else if (box && point) {
          response = await searchPageService.current.fetchFlatsByBox(
            options.accept,
            {
              point,
              box,
              boundaryKey: options.boundaryKey
            },
            query,
            LIMIT_FOR_LIST,
            currentOffset,
            undefined,
            newCalendar
          );
        } else {
          response = await searchPageService.current.fetchFlatsByPath(
            options.accept,
            query,
            options.path,
            LIMIT_FOR_LIST,
            currentOffset,
            undefined,
            newCalendar
          );
        }
        setMeta(response.meta);
        if (withReset) {
          resetFlats();
        }
        setFlats(response.flats);
        setIsLoading(false);
        setShowSkeleton(false);
        setLoadCounter((prevCount) => prevCount + 1);
        setFiltersLoading(DEFAULT_LOADINGS);
      } catch (err) {
        if (!(err instanceof CanceledError)) {
          setIsLoading(false);
          setErrorValue(String(err));
          Sentry.captureException(err);
        }
      }
    },
    [getMeta, searchPageService, flats, meta, query, lastOffset]
  );

  React.useEffect(() => {
    if (mounted.current && !isEqual(query, defaultQuery)) {
      setShowSkeleton(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchFlats(false, true).then(() => {
        window.scrollTo(0, 0);
        setShowSkeleton(false);
      });
      setLoadCounter(0);
      setLastOffset(0);
      setDefaultQuery(query);
    } else {
      mounted.current = true;
    }
  }, [query, defaultQuery]);

  React.useEffect(() => {
    if (loadCounter !== 0 && loadCounter % 3 === 0) {
      setShowLoadButton(true);
    } else {
      setShowLoadButton(false);
    }
  }, [loadCounter]);

  const isLastPage = React.useCallback(() => {
    const { currentPage } = getMeta();
    const lastPage = Math.ceil(meta.total / meta.next.limit);

    return currentPage === lastPage;
  }, [getMeta, meta]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting && flats.length > 0 && !isLastPage() && !isLoading && !showLoadButton) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          fetchFlats().then();
        }
      },
      { rootMargin: '10px' }
    );

    if (options.bottomOfListRef.current) {
      observer.observe(options.bottomOfListRef.current);
    }

    return () => {
      if (options.bottomOfListRef.current) {
        observer.unobserve(options.bottomOfListRef.current);
      }
    };
  }, [fetchFlats, flats, loadCounter, meta, showLoadButton]);

  return {
    showSkeleton,
    isLoading,
    errorValue,
    showLoadButton,
    meta,
    fetchFlats
  };
};
