import styles from './flat-card.module.css';

import classNames from 'classnames';
import get from 'lodash/get';
import { useCallback, useContext } from 'react';

import { Currency } from 'core/entities';
import { CardFlat } from 'core/entities/flats';

import { MapStarredContext } from 'contexts/map-starred';

import { useStarred } from 'hooks/starred';

import { FlatCardInfo } from 'components/flat/flat-card/flat-card-info/flat-card-info';
import { FlatCardSlider } from 'components/flat/flat-card/flat-card-slider/flat-card-slider';
import { FlatLink } from 'components/flat/flat-card/flat-link/flat-link';
import { FlatOwnerAvatar } from 'components/flat/flat-card/flat-owner-avatar/flat-owner-avatar';
import { FlatStarredButton } from 'components/flat/flat-card/flat-starred-button/flat-starred-button';

interface FlatCardProps {
  className?: string;
  mainHost: string;
  flat: CardFlat;
  currency: Currency;
  isRetina: Optional<boolean>;
  isMobileDevice: boolean;
  bookmarksList: Array<number>;
  duration?: Optional<number>;
  onFlatClick?: () => void;
}

export const FlatCardMap = (props: FlatCardProps) => {
  const { addToStarred, removeFromStarred } = useStarred(props.mainHost);
  const mapStarredContext = useContext(MapStarredContext);

  const isStarred = useCallback((flatId: number) => {
    if (props.bookmarksList) {
      return props.bookmarksList.some((id) => id === flatId);
    }
    return true;
  }, []);

  const handleAdd = useCallback(
    async (id: number) => {
      mapStarredContext.add(id);
      await addToStarred(id);
    },
    [addToStarred, mapStarredContext.add]
  );

  const handleRemove = useCallback(
    async (id: number) => {
      mapStarredContext.remove(id);
      await removeFromStarred(id);
    },
    [removeFromStarred, mapStarredContext.remove]
  );

  return (
    <div className={classNames(styles.root, styles.mapCard, props.className)} key={props.flat.id}>
      <div className={styles.top}>
        <FlatStarredButton
          className={styles.favoriteBtn}
          flatId={props.flat.id}
          isActive={isStarred(props.flat.id)}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
        <FlatLink
          mainHost={props.mainHost}
          config={{
            id: props.flat.id,
            url: props.flat.url,
            analytics: {
              Filter: 'on',
              // TODO: positionInCity пока нет в апи
              GeneralRatingPos: get(props, 'flat.positionInCity', 0),
              CityId: get(props, 'flat.city.id', 0),
              URL: typeof window !== 'undefined' && window.location.href
            }
          }}
          ownProps={{
            className: styles.link
          }}
          dateDuration={props.duration}
          sendAnalytics
          onFlatClick={props.onFlatClick}
        >
          <FlatCardSlider flat={props.flat} isRetina={props.isRetina} isMobileDevice={props.isMobileDevice} isMap />
        </FlatLink>
        {'owner' in props.flat && props.flat.owner.profile.avatar && (
          <FlatOwnerAvatar
            className={styles.avatar}
            ownerId={props.flat.owner.id}
            thumbs={props.flat.owner.profile.avatar.thumbs}
            isRetina={props.isRetina}
          />
        )}
      </div>
      <FlatCardInfo className={styles.bottom} flat={props.flat} currency={props.currency} duration={props.duration} />
    </div>
  );
};
