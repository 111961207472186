import styles from './flat-map-page.module.css';

import { useEffect, useState } from 'react';

import { CardFlat } from 'core/entities/flats';
import { City } from 'core/entities/geo';
import { SearchMeta } from 'core/entities/search';

import { MapStarredProvider } from 'contexts/map-starred';

import { useBreakpoints, useWindowDimensions } from 'hooks/use-window-dimensions';

import { SearchMap } from 'components/search-map/search-map';

interface FlatMapPageProps {
  flats: Array<CardFlat>;
  bookmarksList: Array<number>;
  meta: SearchMeta;
  accept: string;
  mainHost: string;
  isMobileDevice: boolean;
  cookies: Optional<string>;
  currentCurrencyId: number;
  city?: Optional<City>;
  isLanding?: boolean;
}

export const FlatMapPage = (props: FlatMapPageProps) => {
  const breakpoints = useBreakpoints();
  const dimensions = useWindowDimensions();
  const [rootHeight, setRootHeight] = useState(0);

  useEffect(() => {
    const headerHeight = {
      mobile: 104,
      tablet: 200,
      desktop: 154
    };
    if (breakpoints.isMobile) {
      setRootHeight(dimensions.height - headerHeight.mobile);
    }
    if (breakpoints.isTablet) {
      setRootHeight(dimensions.height - headerHeight.tablet);
    }
    if (breakpoints.isSmallDesktop || breakpoints.isDesktop) {
      setRootHeight(dimensions.height - headerHeight.desktop);
    }
  }, [breakpoints, dimensions.height]);

  return (
    <MapStarredProvider bookmarksList={props.bookmarksList}>
      <div className={styles.root} style={{ height: rootHeight }}>
        <SearchMap
          flats={props.flats}
          meta={props.meta}
          city={props.city}
          accept={props.accept}
          mainHost={props.mainHost}
          isMobileDevice={props.isMobileDevice}
          cookies={props.cookies}
          isLanding={props.isLanding}
          currentCurrencyId={props.currentCurrencyId}
        />
      </div>
    </MapStarredProvider>
  );
};
